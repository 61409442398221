import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const emailRef = useRef();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    console.log(email);
  };

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <div className="card shadow-lg">
      <div className="card-body">
        <div className="text-center">
          <h1 className="h3">Forgot password</h1>
          <p>Enter your email address to recover your password.</p>
        </div>

        <form className="mt-4" onSubmit={handleForgotPassword}>
          <div className="mb-3">
            <input
              ref={emailRef}
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="d-grid mt-5">
            <button className="btn btn-warning btn-lg" type="submit">
              Reset Password
            </button>
          </div>
        </form>

        <div className="text-center mt-3">
          <Link to="/" className="btn-link text-decoration-none">
            Back to login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
