import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Select from "react-select";

const PolicyForm = (props) => {
  const [policy, setPolicy] = useState({});
  const [newPolicy, setNewPolicy] = useState({});
  const [dropDowns, setDropDowns] = useState({ client: [] });
  const navigate = useNavigate();
  const { id, target, target_id } = useParams();
  const { api, abortSignal, auth } = useApi();
  const nameRef = useRef();
  const { scope = "add" } = props;

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...newPolicy,
        ClientId: newPolicy?.ClientId?.value || "",
      };

      const response =
        scope === "edit"
          ? await api.edit(`/policies/${id}`, data)
          : await api.add("/policies", data);
      if (response.success) navigate(-1, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const getDropDowns = async () => {
    try {
      const models = ["client"];
      const { data } = await api.list(`/dropdowns`, models);
      setDropDowns(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (dropDowns && policy) {
      setNewPolicy({
        ...policy,
        ClientId:
          dropDowns?.client?.find(
            (client) => client.value === policy.ClientId
          ) || "",
      });

      if (target === "clients" && target_id) {
        setNewPolicy({
          ...policy,
          ClientId:
            dropDowns?.client?.find((client) => client.value === target_id) ||
            "",
        });
      }
    }
  }, [dropDowns, policy]);

  useEffect(() => {
    scope === "add" && setNewPolicy({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/policies/${id}`);
        if (response.success) setPolicy(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();
    getDropDowns();
    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth, id]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policies">Policies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} Policy
          </h1>

          <p className="lead">{scope === "edit" ? "Edit" : "Add new"} Policy</p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/policies" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Policies
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave}>
                    <div className="card-body">
                      <div className="row mb-3">
                        <label
                          htmlFor="client"
                          className="col-sm-3 col-form-label"
                        >
                          Client
                        </label>
                        <div className="col-sm-9">
                          <Select
                            id="client"
                            ref={nameRef}
                            options={
                              dropDowns && dropDowns["client"]
                                ? dropDowns["client"]
                                : []
                            }
                            onChange={(selectedOption) =>
                              setNewPolicy({
                                ...newPolicy,
                                ClientId: selectedOption,
                              })
                            }
                            value={newPolicy?.ClientId || ""}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Text input with help --> */}
                      <div className="row mb-3">
                        <label htmlFor="client" className="col-sm-3 form-label">
                          Info
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            id="client"
                            type="text"
                            className="form-control"
                            placeholder="Info"
                            aria-describedby="clientHelp"
                            onChange={(e) =>
                              setNewPolicy({
                                ...newPolicy,
                                info: e.target.value,
                              })
                            }
                            value={newPolicy?.info || ""}
                            rows="12"
                          ></textarea>
                          <div id="clientHelp" className="form-text">
                            Enter details of the Policy
                          </div>
                        </div>
                      </div>
                      {/* <!-- END : Text input with help --> */}
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PolicyForm;
