import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import AddPolicyItem from "./AddPolicyItem";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";
import usePagination from "../../hooks/usePagination";
import useReload from "../../hooks/useReload";
import useApp from "../../hooks/useApp";
import { FilterBar } from "../../widgets";
import ClientPolicyRequirement from "./ClientPolicyRequirement";

const PolicyItemList = (props) => {
  const { toggleReload } = useReload();
  const { api, auth } = useApi();
  const { Paginate, searchParamString } = usePagination();
  const { id = "" } = useParams();
  const [showUnderwrite, setShowUnderwrite] = useState(false);
  const [policyToUnderwrite, setPolicyToUnderwrite] = useState({});

  const removePolicy = async (policyItemId = null) => {
    const callRemove = async () => {
      try {
        if (policyItemId) {
          const response = await api.delete(
            `/policies/policy-items/${policyItemId}`
          );
          if (response) {
            Swal.fire("Deleted!", `Policy removed`, "success");
            toggleReload();
          } else {
            Swal.fire("Delete failed!", `Policy not provided`, "info");
          }
        } else {
          Swal.fire("Delete failed!", `Policy not provided`, "error");
        }
      } catch (error) {
        // console.error(error);
        Swal.fire(
          "Error",
          `Failed to remove policy - ${error.message}`,
          "error"
        );
      }
    };
    Swal.fire({
      title: "Remove Policy Item?",
      text: "Do you want to continue",
      icon: "warning",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        callRemove();
      }
    });
  };

  const underwrite = async (policyItemId) => {
    if (policyItemId) {
      const policy = props?.items?.find((item) => item.id === policyItemId);
      setPolicyToUnderwrite(policy);
      setShowUnderwrite(true);
    }
  };

  useEffect(() => {}, [auth, searchParamString, id]);
  const { allowAdd = true } = props;

  return (
    <>
      {showUnderwrite ? (
        <UnderwriteForm
          setShowUnderwrite={setShowUnderwrite}
          policy={policyToUnderwrite}
        />
      ) : (
        <>
          <div className="d-md-flex align-items-baseline mt-3">
            {allowAdd && <AddPolicyItem />}
            <FilterBar />
          </div>
          <div className="table-responsive bg-white mb-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Created</th>
                  <th>Product</th>
                  <th>Expires</th>
                  <th className="text-center">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {props.items &&
                  props.items.map((policy) => (
                    <tr key={policy.id}>
                      <td>{policy?.Company?.name}</td>
                      <td>
                        <pre>{policy.createdAt}</pre>
                      </td>
                      <td>
                        <span className="text-muted">
                          {policy?.Product?.name}
                        </span>
                        <br />
                        {policy?.policyNumber && (
                          <span className="text-primary">
                            {policy?.policyNumber}
                          </span>
                        )}
                        <br />
                        {policy?.premium && (
                          <span className="fw-bold text-info">
                            Premium:{" "}
                            {Number(policy.premium).toLocaleString("en-US")}
                          </span>
                        )}
                      </td>
                      <td>
                        {policy.status === "active" && (
                          <>
                            <span>
                              {moment(policy?.expires).format("DD/MM/YYYY") ||
                                ""}
                            </span>
                            <p className="text-muted">
                              {policy?.expires &&
                                moment().to(moment(policy.expires))}
                            </p>
                          </>
                        )}
                      </td>
                      <td className="text-center fs-5">
                        <div
                          className={
                            policy?.status === "active"
                              ? "badge bg-success"
                              : "badge bg-info"
                          }
                        >
                          {policy.status}
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex flex-wrap justify-content-center">
                          <div className="btn-group">
                            <button
                              onClick={() => removePolicy(policy.id)}
                              title="delete record"
                              type="button"
                              className={
                                policy?.status === "active"
                                  ? "btn btn-icon btn-outline-primary disabled"
                                  : "btn btn-icon btn-outline-primary"
                              }
                            >
                              <i className="demo-psi-recycling fs-5 text-default"></i>
                            </button>
                            {policy.canUnderwrite && (
                              <button
                                onClick={() => underwrite(policy.id)}
                                title="underwrite policy"
                                type="button"
                                className="btn btn-icon btn-outline-primary"
                              >
                                <i className="demo-psi-tag icon-lg fs-5 text-success"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

const UnderwriteForm = (props) => {
  const { setShowUnderwrite = () => {} } = props;
  const [policy, setPolicy] = useState(
    {
      ...props.policy,
      effectiveDate: props.policy.effectiveDate
        ? new Date(props.policy.effectiveDate)
        : new Date(),
    } || {}
  );
  const [underwritePolicy, setUnderwritePolicy] = useState({});
  const [dropDowns, setDropDowns] = useState({ branch: [] });
  const { api, abortSignal, auth } = useApi();
  const { showAlert } = useApp();
  const { toggleReload } = useReload();
  const [startDate, setStartDate] = useState(new Date());
  const policyItemId = props?.policy?.id || "";

  const getDropDowns = async () => {
    try {
      const models = [
        {
          branch: { CompanyId: policy.CompanyId },
        },
      ];
      const { data } = await api.list(`/dropdowns`, models);
      if (data && policy && typeof policy.BranchId === "string")
        setPolicy({
          ...policy,
          BranchId: data.branch.find((item) => item.value == policy.BranchId),
        });
      setDropDowns(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDropDowns();
  }, [auth]);

  const handleUnderwrite = async (e) => {
    e.preventDefault();
    if (!policyItemId) {
      return showAlert("Could not underwrite policy, missing policy", "error");
    }
    try {
      const data = {
        ...policy,
        BranchId: policy?.BranchId?.value || "",
      };

      delete data.Company;
      delete data.Product;
      delete data.PolicyType;
      delete data.id;
      const response = await api.update(
        `/policies/policy-items/${policyItemId}/underwrite`,
        data
      );
      if (response.ok) {
        showAlert("Policy underwritten pending activation", "success");
        toggleReload();
        setShowUnderwrite(false);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const handlePolicyActivation = async () => {
    try {
      const data = {
        ...policy,
        BranchId: policy?.BranchId?.value || "",
      };

      delete data.Company;
      delete data.Product;
      delete data.PolicyType;
      delete data.id;

      const response = await api.update(
        `/policies/policy-items/${policyItemId}/activate`,
        data
      );
      if (response.ok) {
        showAlert("Policy activated", "success");
        toggleReload();
        setShowUnderwrite(false);
      } else {
        showAlert(response.message, "error");
      }
    } catch (error) {}
  };

  const handleRevoke = async () => {
    const callRevoke = async () => {
      try {
        if (policyItemId) {
          const response = await api.update(
            `/policies/policy-items/${policyItemId}/revoke`
          );
          if (response.ok) {
            Swal.fire("Revoked!", `Policy revoked`, "success");
            toggleReload();
            setShowUnderwrite(false);
          } else {
            Swal.fire("Revoke failed!", `Policy not provided`, "info");
          }
        } else {
          Swal.fire("Revoke failed!", `Policy not provided`, "error");
        }
      } catch (error) {
        console.error(error);
        Swal.fire(
          "Error",
          `Failed to remove policy - ${error.message}`,
          "error"
        );
      }
    };
    Swal.fire({
      title: "Revoke Policy Item?",
      text: "Do you want to continue",
      icon: "warning",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        callRevoke();
      }
    });
  };

  useEffect(() => {
    setPolicy({
      ...policy,
      expires: moment(policy.effectiveDate)
        .add(policy?.duration || 12, "months")
        .format("YYYY-MM-DD"),
    });
  }, [policy?.duration, policy?.effectiveDate]);

  return (
    <section>
      <h2 className="mb-0 mt-2">Underwrite Policy</h2>
      <div className="d-md-flex align-items-baseline mt-3">
        <button
          onClick={() => setShowUnderwrite(false)}
          className="btn btn-info hstack gap-2 mb-3"
        >
          <i className="demo-psi-file fs-4"></i>
          <span className="vr"></span>View Policies
        </button>
        <div className="d-flex align-items-center gap-1 text-nowrap ms-auto mb-3">
          <span className="d-none d-md-inline-block me-2">Sort by : </span>
          <select className="d-inline-block w-auto form-select">
            <option value="date-created">Date Created</option>
            <option value="date-modified">Date Modified</option>
            <option value="frequency-used">Frequency Used</option>
            <option value="alpabetically">Alpabetically</option>
            <option value="alpabetically-reversed">
              Alpabetically Reversed
            </option>
          </select>

          <button type="button" className="btn btn-light">
            Filter
          </button>
          <button type="button" className="btn btn-light btn-icon">
            <i className="demo-pli-gear fs-5"></i>
          </button>
        </div>
      </div>

      <div className="accordion mb-2" id="_dm-gapAccordion">
        <div className="accordion-item mt-1">
          <div className="accordion-header" id="_dm-gapAccHeadingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#_dm-policyRequirement`}
              aria-expanded="false"
              aria-controls={`_dm-policyRequirement`}
            >
              <h5 className="mb-3">Policy Requirements</h5>
            </button>
          </div>
          <div
            id={`_dm-policyRequirement`}
            className="accordion-collapse collapse"
            aria-labelledby="_dm-gapAccHeadingOne"
            data-bs-parent="#_dm-gapAccordion"
          >
            <div className="accordion-body">
              <ClientPolicyRequirement policy={policy} />
            </div>
          </div>
        </div>
      </div>
      <div className="card h-100">
        <form
          onSubmit={handleUnderwrite}
          autoComplete="off"
          id="underwriteForm"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-xs-12">
                <div className="row mb-3">
                  <label htmlFor="product" className="col-sm-3 col-form-label">
                    Product
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="product"
                      type="text"
                      className="form-control-plaintext"
                      defaultValue={policy?.Product?.name}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="company" className="col-sm-3 col-form-label">
                    Company
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="company"
                      type="text"
                      className="form-control-plaintext"
                      defaultValue={policy?.Company?.name}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="created" className="col-sm-3 col-form-label">
                    Date created
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="created"
                      type="text"
                      className="form-control-plaintext"
                      defaultValue={policy?.createdAt}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="effective-date"
                    className="col-sm-3 col-form-label"
                  >
                    Effective Date
                  </label>
                  <div className="col-sm-9">
                    <DatePicker
                      id="effective-date"
                      className="form-control"
                      selected={policy?.effectiveDate || startDate}
                      onChange={(date) =>
                        setPolicy({ ...policy, effectiveDate: date })
                      }
                      isClearable
                      placeholderText="I have been cleared!"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="expires" className="col-sm-3 col-form-label">
                    Expires
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="expires"
                      type="text"
                      className="form-control-plaintext"
                      onChange={(e) => {}}
                      value={moment(policy?.expires).format("DD/MM/YYYY") || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xs-12">
                <div className="row mb-3">
                  <label htmlFor="branch" className="col-sm-3 col-form-label">
                    Underwriting Branch
                  </label>
                  <div className="col-sm-9">
                    <Select
                      id="branch"
                      options={
                        dropDowns && dropDowns["branch"]
                          ? dropDowns["branch"]
                          : []
                      }
                      onChange={(selectedOption) =>
                        setPolicy({ ...policy, BranchId: selectedOption })
                      }
                      value={policy?.BranchId || ""}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="premium" className="col-sm-3 col-form-label">
                    Effective Premium
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="premium"
                      type="number"
                      min={0}
                      step={"0.01"}
                      className="form-control"
                      onChange={(e) =>
                        setPolicy({
                          ...policy,
                          premium: e.target.value,
                        })
                      }
                      value={policy?.premium || "0.00"}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="policy-number"
                    className="col-sm-3 col-form-label"
                  >
                    Policy Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="policy-number"
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        setPolicy({
                          ...policy,
                          policyNumber: e.target.value,
                        })
                      }
                      value={policy?.policyNumber || ""}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label htmlFor="duration" className="col-sm-3 col-form-label">
                    Duration
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="duration"
                      type="number"
                      required
                      placeholder="Duraton in months"
                      className="form-control"
                      onChange={(e) =>
                        setPolicy({ ...policy, duration: e.target.value })
                      }
                      value={policy?.duration || 12}
                      min={1}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Text input with help --> */}
            <div className="row mb-3">
              <div className="col-sm-12">
                <textarea
                  id="client"
                  type="text"
                  className="form-control"
                  placeholder="Info"
                  aria-describedby="clientHelp"
                  onChange={(e) =>
                    setPolicy({
                      ...policy,
                      info: e.target.value,
                    })
                  }
                  value={policy?.info || ""}
                  rows="6"
                ></textarea>
                <div id="clientHelp" className="form-text">
                  Enter details of the Policy
                </div>
              </div>
            </div>
            {/* <!-- END : Text input with help --> */}
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <button
                className={
                  policy?.status === "active"
                    ? "btn btn-primary disabled"
                    : "btn btn-primary"
                }
                type="submit"
              >
                Save
              </button>
              {policy?.status === "waiting" && (
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={handlePolicyActivation}
                >
                  Activate
                </button>
              )}

              {policy?.status === "active" && (
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={handleRevoke}
                >
                  Revoke
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default PolicyItemList;
