import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const PolicyTypeList = (props) => {
  return props?.mini ? (
    <PolicyListMini />
  ) : (
    <PolicyListHead>
      <PolicyListMini headless={true} />
    </PolicyListHead>
  );
};

const PolicyListHead = (props) => {
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policy-types">Policy Types</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Policy types List</h1>

          <p className="lead">List of all policy types</p>

          <div className="d-md-flex align-items-baseline my-3">
            <Link
              to="/policy-types/add"
              className="btn btn-info hstack gap-2 mb-3"
            >
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add Policy Type
            </Link>
            <FilterBar />
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">{props.children}</div>
      </div>
    </section>
  );
};

const PolicyListMini = (props) => {
  const [policyTypes, setPolicyTypes] = useState([]);

  const { Paginate, setPages, searchParamString, setOptions } = usePagination();

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const { data } = await api.list(
          `/policies/policy-types${searchParamString}`
        );
        setPolicyTypes(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);

  return (
    <>
      {!props?.headless && (
        <div className="d-md-flex align-items-baseline mt-3">
          <Link to="branches/add" className="btn btn-info hstack gap-2 mb-3">
            <i className="demo-psi-add fs-4"></i>
            <span className="vr"></span>
            Add Policy
          </Link>

          <FilterBar />
        </div>
      )}
      <div className="table-responsive bg-white">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Info</th>
            </tr>
          </thead>
          <tbody>
            {policyTypes &&
              policyTypes.map((policyType) => (
                <tr key={policyType.id}>
                  <td>
                    <Link
                      to={`/policy-types/${policyType.id}`}
                      className="btn-link"
                    >
                      {policyType.name}
                    </Link>
                  </td>
                  <td>
                    <p className="text-break">{policyType.info}</p>
                  </td>
                  {/* <td className="text-center">-</td> */}
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

export default PolicyTypeList;
