import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Select from "react-select";
import useApi from "../../hooks/useApi";

const PolicyTypeRequirementCard = (props) => {
  const [requirements, setRequirements] = useState([]);
  const [requirement, setRequirement] = useState();
  const [dropDowns, setDropDowns] = useState({ policyRequirement: [] });
  const [showAdd, setShowAdd] = useState(false);
  const [removed, setRemoved] = useState(false);
  const { id } = useParams();
  const { api, abortSignal, auth } = useApi();

  const toggleAddRequirement = () => {};

  const addRequirement = async () => {
    // console.log(requirement);
    if (requirement) {
      try {
        const response = await api.add(
          `/policies/policy-types/${id}/requirements`,
          { policyRequirementId: requirement.value }
        );
        if (response.success) {
          setRequirement();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const removeRequirement = async (rid) => {
    if (rid) {
      try {
        const response = await api.delete(
          `/policies/policy-types/${id}/requirements/${rid}`
        );

        if (response.success) {
          setRemoved(!removed);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getDropDowns = async () => {
    try {
      const models = ["policyRequirement"];
      const { data } = await api.list(`/dropdowns`, models);
      setDropDowns(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const filterRequirements = dropDowns.policyRequirement.filter((element) => {
      return !requirements.find((element1) => element1.id == element.value);
    });

    setDropDowns({ ...dropDowns, policyRequirement: filterRequirements });
  }, [requirements]);

  useEffect(() => {
    if (requirement) {
      setShowAdd(true);
    } else {
      setShowAdd(false);
    }
  }, [requirement]);

  useEffect(() => {
    getDropDowns();
    const callApi = async () => {
      try {
        const { data } = await api.list(
          `/policies/policy-types/${id}/requirements`
        );
        setRequirements(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [id, auth, requirement, removed]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Requirements </h5>

        {/* <!-- Custom content --> */}
        <p className="border-top pt-2 text-success">Add Requirement</p>
        <div className="d-flex">
          <div className="flex-grow-1">
            <Select
              options={
                dropDowns && dropDowns["policyRequirement"]
                  ? dropDowns["policyRequirement"]
                  : []
              }
              onChange={(selectedOption) => setRequirement(selectedOption)}
              value={requirement || ""}
            />
          </div>
          {showAdd && (
            <button
              type="button"
              onClick={addRequirement}
              className="btn btn-icon btn-primary ms-2"
            >
              <i className="demo-psi-tag icon-lg fs-5"></i>
            </button>
          )}
        </div>
        <div className="list-group mt-3">
          {requirements &&
            requirements.map((policyRequirement) => (
              <a
                key={policyRequirement.id}
                href="#"
                className="list-group-item list-group-item-action"
                aria-current="true"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h6 className="mb-1">{policyRequirement.name || ""}</h6>
                  <small>
                    <button
                      onClick={() => removeRequirement(policyRequirement.id)}
                      className="btn btn-xs btn-link btn-light text-decoration-none p-1"
                    >
                      <i className="demo-psi-recycling text-danger icon-lg fs-5"></i>
                    </button>
                  </small>
                </div>
                <small>{policyRequirement.type || ""}</small>
              </a>
            ))}
          {!requirements && "No Requirements"}
        </div>
        {/* <!-- END : Custom content --> */}
      </div>
    </div>
  );
};

export default PolicyTypeRequirementCard;
