import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useAuth from "../../hooks/useAuth";
import useApi from "../../hooks/useApi";
import useApp from "../../hooks/useApp";

const Profile = () => {
  const { auth } = useAuth();
  const { user } = auth;
  const { api } = useApi();
  const [password, setPassword] = useState({});
  const { showAlert } = useApp();

  const changePassword = async (event) => {
    event.preventDefault();
    if (password) {
      if (password.repeatPassword !== password.newPassword) {
        showAlert("Password do not matach", "error");
        return;
      }

      try {
        const response = await api.update("/users/change-password", password);
        if (response.ok) {
          showAlert("Password changed successfully", "success");
          setPassword({});
        }
      } catch (error) {}
    }
  };

  const getProfile = async () => {
    try {
      const url = "/users/profile";
      const { data } = await api.read(url);
      return data;
    } catch (error) {
      throw new Error("failed to fetch records");
    }
  };

  const { data: profile } = useQuery(["profile", auth], () => getProfile());

  return (
    <>
      <section id="content" className="content">
        <div className="content__header content__boxed rounded-0">
          <div className="content__wrap d-md-flex align-items-start">
            <figure className="m-0">
              <div className="d-inline-flex align-items-center position-relative pt-xl-5 mb-3">
                <div className="flex-shrink-0">
                  <img
                    className="img-xl rounded-circle"
                    src="./assets/img/profile-photos/5.png"
                    alt="Profile Picture"
                    loading="lazy"
                  />
                </div>
                <div className="flex-grow-1 ms-4">
                  <a href="#" className="h3 btn-link">
                    {user?.name}
                  </a>
                </div>
              </div>
            </figure>
            <div className="d-inline-flex justify-content-end pt-xl-5 gap-2 ms-auto">
              <button className="btn btn-light text-nowrap">
                Edit Profile
              </button>
            </div>
          </div>
        </div>

        <div className="content__boxed">
          <div className="content__wrap">
            <div className="d-md-flex gap-4">
              <div className="w-md-200px flex-shrink-0">
                <h5>About Me</h5>
                <ul className="list-unstyled mb-3">
                  <li className="mb-2">
                    <i className="demo-psi-internet fs-5 me-3"></i>
                    {profile?.email}
                  </li>
                  <li className="mb-2">
                    <i className="demo-psi-old-telephone fs-5 me-3"></i>
                    {profile?.username}
                  </li>
                </ul>
              </div>

              <div className="vr d-none"></div>

              <div className="flex-fill">
                <label htmlFor="current">
                  <h2>Change Password</h2>
                </label>
                <div className="card mb-3">
                  <div className="card-body">
                    <form
                      onSubmit={changePassword}
                      role="form"
                      autoComplete="off"
                    >
                      <div className="mb-2">
                        <label className="form-label" htmlFor="current">
                          Current Password
                        </label>
                        <input
                          className="form-control"
                          id="current"
                          required
                          type="password"
                          value={password?.password || ""}
                          onChange={(event) =>
                            setPassword({
                              ...password,
                              password: event.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label className="form-label" htmlFor="newpass">
                          New Password
                        </label>
                        <input
                          className="form-control"
                          id="newpass"
                          required
                          type="password"
                          value={password?.newPassword || ""}
                          onChange={(event) =>
                            setPassword({
                              ...password,
                              newPassword: event.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label className="form-label" htmlFor="repeatpass">
                          Repeat Password
                        </label>
                        <input
                          className="form-control"
                          id="repeatpass"
                          required
                          type="password"
                          value={password?.repeatPassword || ""}
                          onChange={(event) =>
                            setPassword({
                              ...password,
                              repeatPassword: event.target.value,
                            })
                          }
                        />
                      </div>
                      <button type="submit" className="btn btn-lg btn-primary">
                        Change Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
