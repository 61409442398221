import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import PolicyItemList from "./PolicyItemList";
import useReload from "../../hooks/useReload";
import Swal from "sweetalert2";

const PolicyView = () => {
  const [policy, setPolicy] = useState({});
  const { id } = useParams();
  const { reload } = useReload();
  const navigate = useNavigate();

  const { api, abortSignal, auth } = useApi();

  const deletePolicy = async () => {
    try {
      const response = await api.delete(`/policies/${id}`);

      if (response.ok) navigate(`/policies`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const closePolicy = async () => {
    const callClose = async () => {
      try {
        const response = await api.update(`/policies/${id}/close`);

        if (response.ok) {
          Swal.fire("Policy closed!", `Policy has been closed`, "success");
          navigate(`/policies`);
        } else {
          Swal.fire("Close failed!", `Policy not close`, "info");
        }
      } catch (error) {
        Swal.fire(
          "Close failed!",
          `Policy not close - ${error.message}`,
          "info"
        );
      }
    };
    Swal.fire({
      title: "Close Policy?",
      text: "Do you want to continue",
      icon: "warning",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        callClose();
      }
    });
  };

  useEffect(() => {
    const callApi = async () => {
      try {
        const { data = {} } = await api.read(`/policies/${id}`);
        setPolicy(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [id, auth, reload]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policies">Policies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {policy?.Client?.name || id}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {policy?.Client?.name || "Policy detail"}
          </h1>

          <p className="lead">Detail view of Policy</p>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            <div className="col-xxl-9 col-xl-8 col-lg-7 col-sm-12">
              <PolicyItemList
                items={policy.PolicyItems}
                allowAdd={policy?.status === "running" ? false : true}
              />
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-12">
              {/* <!-- User widget with background color --> */}
              <div className="card mb-3">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <Link
                      to={`/clients/${policy?.ClientId}`}
                      className="d-inline-block h5 btn-link text-truncate mb-2"
                    >
                      {policy?.Client?.name}
                    </Link>
                    <Link
                      to="edit"
                      className="btn btn-xs btn-hover btn-light text-decoration-none p-1"
                    >
                      <i className="demo-psi-pen-5 fs-5"></i>
                    </Link>
                  </div>
                  <div>
                    <small className="text-muted">Client Phone number</small>
                    <p className="text-break border-bottom">
                      {policy?.Client?.phonenumber}
                    </p>
                    <small className="text-muted">Client Email</small>
                    <p className="text-break border-bottom">
                      {policy?.Client?.email}
                    </p>
                    <small className="text-muted">Policy Info</small>
                    <pre
                      className="word-wrap:break-word"
                      //   style={{ "white-space": "pre-wrap" }}
                    >
                      {policy?.info}
                    </pre>
                  </div>
                  <div className="d-grid gap-2  mt-3">
                    {policy?.status !== "running" && (
                      <button
                        type="button"
                        onClick={closePolicy}
                        className="btn btn-info btn-lg"
                      >
                        Close Policy
                      </button>
                    )}

                    {policy?.itemsCount < 1 && (
                      <button
                        type="button"
                        onClick={deletePolicy}
                        className="btn btn-danger btn-lg"
                      >
                        Delete Policy
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* <AddPolicyItem /> */}
              {/* <!-- END : User widget with background color --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PolicyView;
