import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "./useAuth";
import { AppContext } from "../context/AppProvider";
import useApp from "./useApp";
// import useIsLoading from "../useIsLoading"

let abortSignal = null;

const useApi = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = "https://springapi.iuapps.net/api";
  const LOGIN_URL = `${url}/auth/login`;
  const REFRESH_URL = `${url}/auth/refresh`;
  const LOGOUT_URL = `${url}/auth/logout`;
  let request = new Request(url);

  const { auth, setAuth } = useAuth();
  const { setPaginationOptions, setPages } = useContext(AppContext).pagination;
  const { setIsLoading, showAlert } = useApp();

  const headers = {
    "Content-Type": "application/json;charset=utf-8",
    Authorization: `Bearer ${auth.accessToken}`,
  };

  const apiResponse = {
    success: true,
    message: "ok",
    data: null,
    status: 200,
  };

  const createRequest = (endpoint, method = "GET", data = null) => {
    //setIsLoading(true);
    abortSignal = new AbortController();

    let options = {
      method: method,
      mode: "cors",
      signal: abortSignal.signal,
      headers: headers,
    };
    if (data) {
      let apiOptions;
      if (data instanceof FormData) {
        let head = { ...headers };
        delete head["Content-Type"];

        apiOptions = {
          ...options,
          body: data,
          headers: head,
        };
      } else {
        apiOptions = {
          ...options,
          body: JSON.stringify(data),
          headers: headers,
        };
      }
      options = apiOptions;
    }

    request = new Request(url + endpoint, options);
    return request;
  };

  const checkResponse = async (response) => {
    setIsLoading(false);
    if (!response) {
      apiResponse.success = false;
      apiResponse.message = "Error occured";
      apiResponse.status = 500;
      return apiResponse;
    }

    if (response.ok) {
      try {
        // const output = await response.json();
        return await response;
      } catch (error) {
        apiResponse.success = false;
        apiResponse.message = error.message;
        apiResponse.status = 400;
      }
    } else {
      if (response.status !== 401) {
        showAlert(response.statusText, "error");
      }
      apiResponse.message = response.statusText;
      apiResponse.success = false;

      apiResponse.status = response.status;
      switch (response.status) {
        case 401:
          const refreshresponse = await refreshRequest();
          return processRefresh(refreshresponse);
          break;
        case 404:
          break;
        default:
          break;
      }
    }
    return apiResponse;
  };

  const processResponse = async (response) => {
    setIsLoading(false);
    if (!response) {
      throw new Error("Error occured");
    }

    if (response.ok) {
      const output = await response.json();
      return output;
    } else {
      switch (response.status) {
        case 401:
          return refreshRequest();
        default:
          throw new Error(response.statusText);
      }
    }
  };

  const processRefresh = async (response) => {
    if (!response) {
      apiResponse.success = false;
      apiResponse.message = "Error occured";
      apiResponse.status = 500;
      return apiResponse;
    }
    if (response.ok) {
      try {
        const data = await response.json();
        if (data) {
          setAuth(data);
        }
        request.headers.delete("Authorization");
        request.headers.append("Authorization", `Bearer ${data.accessToken}`);

        //resend original request
        const resendOutput = await fetch(request);
        return await checkResponse(resendOutput);
      } catch (error) {
        apiResponse.success = false;
        apiResponse.message = error.message;
        apiResponse.status = 500;
      }
    } else {
      apiResponse.success = false;
      apiResponse.message = response.statusText;
      apiResponse.status = response.status;
      switch (response.status) {
        case 401:
          navigate("/login", { state: { from: location }, replace: true });
          apiResponse.message = "Wrong username or password";
          break;
        default:
          apiResponse.message = "Invalid username or password";
          break;
      }
    }
    return false;
  };

  const refreshRequest = async () => {
    //remove authorization header before sending login request
    const logingHeaders = { ...headers };
    delete logingHeaders.Authorization;
    try {
      const response = await fetch(REFRESH_URL, {
        mode: "cors",
        method: "GET",
        headers: logingHeaders,
        credentials: "include",
      });
      return response;
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  const processLogout = async (response) => {
    if (!response) {
      return false;
    } else {
      if (response.ok) {
        setAuth("");
        return true;
      }
    }
    return false;
  };

  const logoutRequest = async () => {
    //remove authorization header before sending login request
    const logingHeaders = { ...headers };
    delete logingHeaders.Authorization;
    try {
      const response = await fetch(LOGOUT_URL, {
        mode: "cors",
        method: "GET",
        headers: logingHeaders,
        credentials: "include",
      });
      return processLogout(response);
    } catch (error) {
      return processLogin(false);
    }
  };

  const processLogin = async (response) => {
    let user = "";
    if (!response) {
      apiResponse.success = false;
      apiResponse.message = "Error occured";
      apiResponse.status = 500;
      return apiResponse;
    }
    if (response.ok) {
      try {
        const data = await response.json();
        if (data) {
          user = data.user.name;
          setAuth(data);
        }
        showAlert(`Welcome back ${user}`, "success");
        apiResponse.success = true;
        apiResponse.data = data;
        return apiResponse;
      } catch (error) {
        apiResponse.success = false;
        apiResponse.message = error.message;
        apiResponse.status = 500;
      }
    } else {
      apiResponse.success = false;
      apiResponse.message = response.statusText;
      apiResponse.status = response.status;
      switch (response.status) {
        case 401:
          apiResponse.message = "Wrong username or password";
          break;
        default:
          apiResponse.message = "Invalid username or password";
          break;
      }
    }
    return apiResponse;
  };

  const api = {
    login: async (credential) => {
      //remove authorization header before sending login request
      const logingHeaders = { ...headers };
      delete logingHeaders.Authorization;
      try {
        const response = await fetch(LOGIN_URL, {
          mode: "cors",
          method: "POST",
          body: JSON.stringify(credential),
          headers: logingHeaders,
          credentials: "include",
        });

        // if (response.ok) {
        //   const data = await response.json();
        //   console.log(data);
        //   // showAlert(`Welcome back ${user}`, "success");
        // }
        return processLogin(response);
      } catch (error) {
        return processLogin(false);
      }
    },
    refresh: async () => {
      try {
        const response = await refreshRequest();
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setAuth(data);
            return true;
          }
        }
      } catch (error) {
        console.error(error.message);
      }
      return false;
    },
    isValidRefresh: async () => {
      try {
        const response = await refreshRequest();
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setAuth(data);
            return true;
          }
        }
      } catch (error) {
        console.error(error.message);
      }
      return false;
    },
    logout: async () => {
      try {
        const response = await logoutRequest();
        showAlert("You have successfully logout", "success");
        return response?.success && false;
      } catch (error) {
        //console.error(error.message);
      }
      return true;
    },
    list: async (endpoint, data = null) => {
      try {
        let method = "GET";
        if (data) {
          method = "POST";
        }
        const output = await fetch(createRequest(endpoint, method, data));
        let response = await checkResponse(output);
        if (response) {
          response = await response.json();
          const data = response?.data?.rows || response?.data || [];
          const records = response?.data?.count || 0;
          const pagination = response?.pagination || {};

          setPages(records);
          setPaginationOptions(pagination);
          //console.log(data);
          return { data, records, pagination, response };
        } else {
          return checkResponse(false);
        }
      } catch (error) {
        console.error(error.message);
        return checkResponse(false);
      }
    },
    add: async (endpoint, data = null) => {
      try {
        const response = await fetch(createRequest(endpoint, "POST", data));
        let output = await checkResponse(response);
        if (output.ok) {
          output = await output.json();
          return output;
        } else {
          return false;
        }
      } catch (error) {
        // console.error(error.message);
        return checkResponse(false);
      }
    },
    read: async (endpoint) => {
      try {
        const response = await fetch(createRequest(endpoint));
        let output = await checkResponse(response);
        if (output.ok) {
          output = await output.json();
          return output;
        } else {
          return { data: {} };
        }
      } catch (error) {
        // console.error(error.message);
        return checkResponse(false);
      }
    },
    edit: async (endpoint, data = null) => {
      try {
        const response = await fetch(createRequest(endpoint, "PUT", data));
        let output = await checkResponse(response);
        if (output.ok) {
          output = await output.json();
          return output;
        } else {
          return false;
        }
      } catch (error) {
        return checkResponse(false);
      }
    },
    update: async (endpoint, data = null) => {
      try {
        const response = await fetch(createRequest(endpoint, "PATCH", data));
        return checkResponse(response);
      } catch (error) {
        return checkResponse(false);
      }
    },
    delete: async (endpoint, data = null) => {
      try {
        const response = await fetch(createRequest(endpoint, "DELETE"));
        const output = await checkResponse(response);

        if (output.ok) {
          showAlert("Record deleted", "success");
        }
        return output;
      } catch (error) {
        return checkResponse(false);
      }
    },
    getAll: async (endpoint, data = null) => {
      try {
        let method = "GET";
        if (data) {
          method = "POST";
        }
        const output = await fetch(createRequest(endpoint, method, data));
        const response = await processResponse(output);
        if (response.success) {
          const data = response?.data?.rows || response?.data || [];
          const records = response?.data?.count || 0;
          const pagination = response?.pagination || {};

          setPages(records);
          setPaginationOptions(pagination);

          return { data, records, pagination, response };
        } else {
          throw new Error("Error occured");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
    getOne: async (endpoint) => {
      try {
        const response = await fetch(createRequest(endpoint));
        const output = await processResponse(response);
        return output.data;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    addNew: async (endpoint, data = null) => {
      try {
        const response = await fetch(createRequest(endpoint, "POST", data));
        return checkResponse(response);
      } catch (error) {
        // console.error(error.message);
        return checkResponse(false);
      }
    },
    modify: async (endpoint, data = null) => {
      try {
        const response = await fetch(createRequest(endpoint, "PUT", data));
        return checkResponse(response);
      } catch (error) {
        return checkResponse(false);
      }
    },
  };

  return { api, abortSignal, auth };
};

export default useApi;
