import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import ProductRequirementCard from "./ProductRequirementCard";

const ProductView = () => {
  const [product, setProduct] = useState({});
  const { id } = useParams();

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.read(`/products/${id}`);
        if (response.success) setProduct(response.data);
      } catch (error) {
        // console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [id, auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {product?.name || id}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {product?.name || "Product detail"}
          </h1>

          <p className="lead">Detail view of Product</p>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            <div className="col-xxl-9 col-xl-8 col-lg-7 col-sm-12">
              {<Outlet />}
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-12">
              {/* <!-- User widget with background color --> */}
              <div className="card mb-3">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <a
                      href="#"
                      className="d-inline-block h5 btn-link text-truncate mb-2"
                    >
                      {product?.name}
                    </a>
                    <Link
                      to="edit"
                      className="btn btn-xs btn-hover btn-light text-decoration-none p-1"
                    >
                      <i className="demo-psi-pen-5 fs-5"></i>
                    </Link>
                  </div>
                  <p className="text-break">{product?.info}</p>
                  <p className="text-break text-primary">
                    {product?.Company?.name}
                  </p>
                  <p className="text-break text-success fw-bold">
                    {product?.PolicyType?.name}
                  </p>
                  <div>
                    <small>Commission Rate</small>
                    <p className="text-primary fw-bold">
                      {product?.commissionRate}
                    </p>
                  </div>
                  <div className="mt-4 pt-3 d-flex align-items-center border-top">
                    <div className="d-flex g-2 ms-auto">
                      <span
                        className="btn btn-icon btn-sm btn-link text-head px-2 py-0"
                        title="Companies offering policy types"
                      >
                        <i className="demo-pli-heart-2 fs-5 me-2"></i>0
                      </span>
                      <span
                        className="btn btn-icon btn-sm btn-link text-head px-2 py-0"
                        title="Number of products"
                      >
                        <i className="demo-pli-speech-bubble-5 fs-5 me-2"></i>0
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <ProductRequirementCard />
              {/* <!-- END : User widget with background color --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductView;
