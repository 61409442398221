import React, { useEffect, useState, useTransition } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import useAvatar from "../../hooks/useAvatar";
import { FilterBar } from "../../widgets";

const ClientListHeader = (props) => {
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/clients">Clients</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Clients List</h1>

          <p className="lead">List of of clients</p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/clients/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">{props.children}</div>
      </div>
    </section>
  );
};

const ClientListMain = (props) => {
  const { api, abortSignal, auth } = useApi();
  const [clients, setClients] = useState([]);
  const { Paginate, searchParamString } = usePagination();
  const avatar = useAvatar();
  const { id = "" } = useParams();
  const { scope = "" } = props;

  useEffect(() => {
    const url = `/clients${scope}${id}${searchParamString}`;
    const callApi = async () => {
      try {
        const { data } = await api.list(url);
        setClients(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);
  return (
    <>
      {!props?.headless && (
        <>
          <h2 className="mb-0 mt-2">Clients</h2>
          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/clients/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </>
      )}
      <div className="row">
        {clients &&
          clients.map((client, index) => (
            <div
              className="col-sm-12 col-md-6 col-xl-4 col-xxl-3 mb-3"
              key={client.id}
            >
              <div className="card">
                <div className="card-body">
                  {/* <!-- Profile picture and short information --> */}
                  <div
                    className="d-flex align-items-center position-relative pb-3"
                    style={{ height: 100 }}
                  >
                    <div className="flex-shrink-0">
                      <img
                        className="img-md rounded-circle"
                        src={avatar[index % 6]}
                        alt="Profile Picture"
                        loading="lazy"
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <Link
                        to={`/clients/${client.id}`}
                        className="h5 stretched-link btn-link"
                      >
                        <span className="text-break text-wrap">
                          {client.name}
                        </span>
                      </Link>
                      <p className="text-muted m-0 text-break text-wrap">
                        {client.phonenumber}
                      </p>
                      <p className="m-0 text-break text-wrap">{client.email}</p>
                    </div>
                  </div>

                  {/* <!-- END : Profile picture and short information --> */}

                  {/* <!-- Options buttons --> */}
                  <div className="mt-3 pt-2 text-center border-top">
                    <div className="d-flex justify-content-center gap-0">
                      <a
                        href={`https://wa.me/${client.fullPhonenumber}`}
                        className="btn btn-sm btn-hover btn-outline-light"
                        target="_blank"
                        title="Whatsapp"
                      >
                        <i className="d-block demo-pli-technorati demo-pli-speech-bubble-2 text-muted fs-3 mb-2"></i>{" "}
                      </a>
                      <a
                        href={`tel:${client.phonenumber}`}
                        className="btn btn-sm btn-hover btn-outline-light"
                        title="Call"
                      >
                        <i className="d-block demo-pli-consulting text-muted fs-3 mb-2"></i>{" "}
                      </a>
                      <a
                        href={`mailto:${client.email}`}
                        className="btn btn-sm btn-hover btn-outline-light"
                        title="Email"
                      >
                        <i className="d-block demo-pli-mail text-muted fs-3 mb-2"></i>{" "}
                      </a>
                      <Link
                        to={`/clients/${client.id}/edit`}
                        className="btn btn-sm btn-hover btn-outline-light"
                        title="Edit"
                      >
                        <i className="d-block demo-pli-pen-5 text-muted fs-3 mb-2"></i>{" "}
                      </Link>
                    </div>
                  </div>
                  {/* <!-- END : Options buttons --> */}
                </div>
              </div>
            </div>
          ))}
      </div>
      <Paginate />
    </>
  );
};

const ClientList = (props) => {
  return props?.scope ? (
    <ClientListMain scope={props.scope} />
  ) : (
    <ClientListHeader>
      <ClientListMain headless={true} />
    </ClientListHeader>
  );
};

export default ClientList;
