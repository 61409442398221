import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";

function Login() {
  const { api, abortController } = useApi();
  const [credential, setCredential] = useState({
    username: "",
    password: "",
  });
  const [errMessage, setErrMessage] = useState("");
  const usernameRef = useRef();
  const errRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/dashboard";

  const handleLogin = async (e) => {
    e.preventDefault();
    if (credential) {
      const response = await api.login(credential);
      if (response.success) {
        navigate(from, { replace: true });
      } else {
        setErrMessage(response.message);
      }
    } else {
      setErrMessage("Please provide username and password");
    }

    usernameRef.current.focus();
  };

  useEffect(() => {
    usernameRef.current.focus();
  }, []);
  return (
    <div className="card shadow-lg">
      <div className="card-body">
        <div className="text-center">
          <h1 className="h3">Surge Login</h1>
          <p className={errMessage && "text-danger"}>
            {errMessage ? errMessage : "Sign In to your Surge account"}
          </p>
        </div>

        <form className="mt-4" onSubmit={handleLogin}>
          <div className="mb-3">
            <input
              ref={usernameRef}
              type="text"
              className="form-control"
              placeholder="Phonenumber"
              required
              value={credential.username}
              onChange={(e) => {
                setCredential({ ...credential, username: e.target.value });
                setErrMessage("");
              }}
            />
          </div>

          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              required
              value={credential.password}
              onChange={(e) => {
                setCredential({ ...credential, password: e.target.value });
                setErrMessage("");
              }}
            />
          </div>

          <div className="form-check">
            <input
              id="_dm-loginCheck"
              className="form-check-input"
              type="checkbox"
            />
            <label htmlFor="_dm-loginCheck" className="form-check-label">
              Remember me
            </label>
          </div>

          <div className="d-grid mt-5">
            <button className="btn btn-primary btn-lg" type="submit">
              Sign In
            </button>
          </div>
        </form>

        <div className="d-flex justify-content-between mt-4">
          <Link to="/forgot-password" className="btn-link text-decoration-none">
            Forgot password ?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
