import React from "react";
import { Link } from "react-router-dom";

const NavigateBackButton = () => {
  return (
    <Link to={-1} className="btn btn-light">
      BACK
    </Link>
  );
};

export default NavigateBackButton;
