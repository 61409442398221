import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useAvatar from "../../hooks/useAvatar";

const ClientView = () => {
  const [client, setClient] = useState({});
  const { id } = useParams();
  const avatar = useAvatar(1);

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.read(`/clients/${id}`);
        if (response.success) setClient(response.data);
      } catch (error) {
        //console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [id, auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed rounded-0">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/clients">Clients</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {client?.info?.name || id}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {client?.info?.name || "client detail"}
          </h1>

          <p className="lead">Detail view of client</p>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            <div className="col-xxl-9 col-xl-8 col-lg-7 col-sm-12">
              {<Outlet />}
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-12">
              {/* <!-- User widget with background color --> */}
              <div className="card text-white mb-3">
                <div className="card-body">
                  {/* <!-- Profile picture and short information --> */}
                  <div className="text-center position-relative">
                    <div className="pt-2 pb-3">
                      <img
                        className="img-lg rounded-circle"
                        src={avatar}
                        alt="Profile Picture"
                        loading="lazy"
                      />
                    </div>
                    <span className="h5 stretched-link btn-link">
                      {client?.name || ""}
                    </span>
                    <p className="text-muted">{client?.info?.phonenumber}</p>
                  </div>
                  <div className="d-flex justify-content-center gap-2">
                    <a
                      href={`https://wa.me/${client?.info?.fullPhonenumber}`}
                      className="btn btn-sm btn-hover btn-outline-light"
                      target="_blank"
                    >
                      <i className="d-block demo-pli-technorati demo-pli-speech-bubble-2 text-muted fs-3 mb-2"></i>{" "}
                      Whatsapp
                    </a>
                    <a
                      href={`tel:${client?.info?.phonenumber}`}
                      className="btn btn-sm btn-hover btn-outline-light"
                    >
                      <i className="d-block demo-pli-consulting text-muted fs-3 mb-2"></i>{" "}
                      Call
                    </a>
                    <a
                      href={`mailto:${client?.info?.email}`}
                      className="btn btn-sm btn-hover btn-outline-light"
                    >
                      <i className="d-block demo-pli-mail text-muted fs-3 mb-2"></i>{" "}
                      Email
                    </a>
                    <Link
                      to="edit"
                      className="btn btn-sm btn-hover btn-outline-light"
                    >
                      <i className="d-block demo-pli-pen-5 text-muted fs-3 mb-2"></i>{" "}
                      Edit
                    </Link>
                  </div>
                  {/* <!-- END : Profile picture and short information --> */}

                  {/* <!-- Social media buttons --> */}
                  <div className="mt-4 pt-3 d-flex border-top">
                    <div
                      className="btn-group w-100"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="btnradio1"
                      >
                        <div className="text-center">
                          <h4 className="mb-1">
                            {client?.stats?.policyCount || 0}
                          </h4>
                          <small className="text-muted">Policies</small>
                        </div>
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="btnradio2"
                      >
                        <div className="text-center">
                          <h4 className="mb-1">
                            {client?.stats?.claimCount || 0}
                          </h4>
                          <small className="text-muted">Claims</small>
                        </div>
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="btnradio3"
                      >
                        <div className="text-center">
                          <h4 className="mb-1">
                            {client?.stats?.expiringCount || 0}
                          </h4>
                          <small className="text-muted">Expiring</small>
                        </div>
                      </label>
                    </div>
                  </div>
                  {/* <!-- END : Social media buttons --> */}
                </div>
              </div>
              {/* <!-- END : User widget with background color --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientView;
