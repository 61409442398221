import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
// import some from "../assets"
const bg_image = "./assets/premium/boxed-bg/blurred/bg/16.jpg";

const AuthLayout = () => {
  useEffect(() => {
    const body = document.body;
    const root = document.getElementById("root");
    root.classList.toggle("front-container");
    body.style.backgroundImage = `url(${bg_image})`;

    return () => {
      root.classList.toggle("front-container");
      body.style.backgroundImage = "none";
    };
  }, []);
  return (
    <section
      id="content"
      className="container position-absolute top-50 start-50 translate-middle"
    >
      {/* <div className="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <div className="content__wrap"> */}
      <div className="row ">
        <div className="col-md-4 col-sm-12 offset-md-4 ">
          <Outlet></Outlet>
        </div>
      </div>
      {/* </div>
      </div> */}
    </section>
  );
};

export default AuthLayout;
