import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const BranchList = (props) => {
  const [branches, setBranches] = useState([]);
  const { Paginate, searchParamString } = usePagination();
  const { id } = useParams();

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const { data } = await api.list(
          `/companies/${id}/branches${searchParamString}`
        );
        setBranches(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);
  return (
    <>
      <h2 className="mb-0 mt-2">Branches</h2>
      <div className="d-md-flex align-items-baseline mt-3">
        <Link to="branches/add" className="btn btn-info hstack gap-2 mb-3">
          <i className="demo-psi-add fs-4"></i>
          <span className="vr"></span>
          Add Branch
        </Link>

        <FilterBar />
      </div>
      <div className="table-responsive bg-white">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact</th>
              <th>Location</th>
              <th className="text-center">Status</th>
              {/* <th className="text-center">action</th> */}
            </tr>
          </thead>
          <tbody>
            {branches &&
              branches.map((branch) => (
                <tr key={branch.id}>
                  <td className="text-break1">
                    <Link to={`/branches/${branch.id}`} className="btn-link">
                      <span className="text-wrap">{branch.name}</span>
                    </Link>
                  </td>
                  <td className="text-break">
                    <pre className="word-wrap">{branch.contact_person}</pre>
                  </td>
                  <td className="text-break">
                    <pre className="word-wrap">{branch.location}</pre>
                  </td>
                  <td className="text-center fs-5">
                    <div className="badge bg-success">Active</div>
                  </td>
                  {/* <td className="text-center">-</td> */}
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

export default BranchList;
