import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Desktop = () => {
  const { auth } = useAuth();

  return (
    auth?.user && (
      <section id="content" className="content">
        <div className="content__header content__boxed overlapping">
          <div className="content__wrap">
            {/* <!-- Page title and information --> */}
            <h1 className="page-title mb-2">Desktop</h1>
            <h2 className="h5">Welcome back to the Dashboard.</h2>
            <p>
              Scroll down to see quick links
              <br />
            </p>
            {/* <!-- END : Page title and information --> */}
          </div>
        </div>

        <div>
          <div className="content__boxed">
            <div className="content__wrap">
              {/* <!-- Infographics --> */}
              <div className="row my-3 justify-content-center">
                <div className="col-md-4 col-lg-3">
                  <Link to="/clients" className="text-decoration-none">
                    <div className="card text-center mb-3 mb-md-0">
                      <div className="card-body">
                        <div className="h1 my-4">
                          <i className="demo-psi-data-settings display-3 text-head text-opacity-20"></i>
                        </div>
                        <p className="h4">Clients</p>
                        <p className="text-head fw-semibold">
                          Clients Portfolio
                        </p>
                        <small
                          className="d-block text-muted my-3"
                          style={{ height: 40 }}
                        >
                          The client is king
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-lg-3">
                  <Link to="/policies" className="text-decoration-none">
                    <div className="card text-center mb-3 mb-md-0">
                      <div className="card-body">
                        <div className="h1 my-4">
                          <i className="demo-psi-computer-secure display-3 text-head text-opacity-20"></i>
                        </div>
                        <p className="h4">Policies</p>
                        <p className="text-head fw-semibold">
                          Pick of best Insurrers
                        </p>
                        <small
                          className="d-block text-muted my-3"
                          style={{ height: 40 }}
                        >
                          Best and cost-effective cover
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-lg-3">
                  <Link to="/claims" className="text-decoration-none">
                    <div className="card text-center mb-3 mb-md-0">
                      <div className="card-body">
                        <div className="h1 my-4">
                          <i className="demo-psi-consulting display-3 text-head text-opacity-20"></i>
                        </div>
                        <p className="h4">Claims</p>
                        <p className="text-head fw-semibold">
                          We are here 24/7
                        </p>
                        <small
                          className="d-block text-muted my-3"
                          style={{ height: 40 }}
                        >
                          Work with our partners to pay claims promptly
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 col-lg-3">
                  <Link to="/products" className="text-decoration-none">
                    <div className="card text-center">
                      <div className="card-body">
                        <div className="h1 my-4">
                          <i className="demo-psi-add-cart display-3 text-head text-opacity-20"></i>
                        </div>
                        <p className="h4">Products</p>
                        <p className="text-head fw-semibold">
                          Taylor made products
                        </p>
                        <small
                          className="d-block text-muted my-3"
                          style={{ height: 40 }}
                        >
                          Taylor made products
                        </small>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              {/* <!-- END : Infographics --> */}
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default Desktop;
