import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import Select from "react-select";

const RequirementForm = (props) => {
  const [requirement, setRequirement] = useState({});
  const [dropDowns, setDropDowns] = useState({
    type: [
      { value: "text", label: "Text" },
      { value: "check", label: "check" },
      { value: "file", label: "File" },
      { value: "date", label: "Date" },
    ],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const from = location?.state?.from?.pathname || `/policy-requirements`;
  const { api, abortSignal, auth } = useApi();
  const nameRef = useRef();
  const { scope = "add" } = props;

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const data = { ...requirement, type: requirement?.type?.value || "" };
      const response =
        scope === "edit"
          ? await api.edit(`/policies/requirements/${id}`, data)
          : await api.add("/policies/requirements", data);
      if (response.success) navigate(from, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scope === "add" && setRequirement({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/policies/requirements/${id}`);

        if (response.success) {
          let selectedType = {};
          if (response?.data?.type) {
            selectedType = dropDowns.type.find((element) => {
              return element.value == response.data.type;
            });
          }
          setRequirement({ ...response.data, type: selectedType });
        }
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Policies</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policy-requirements">Requirements</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} Requirement
          </h1>

          <p className="lead">
            {scope === "edit" ? "Edit" : "Add new"} Requirement
          </p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link
              to="/policy-requirements"
              className="btn btn-info hstack gap-2 mb-3"
            >
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Requirements
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave} autoComplete="off">
                    <div className="card-body">
                      {/* <!-- Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="name"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={nameRef}
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={(e) =>
                              setRequirement({
                                ...requirement,
                                name: e.target.value,
                              })
                            }
                            value={requirement?.name || ""}
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- END : Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="input-type"
                          className="col-sm-3 col-form-label"
                        >
                          Input Type
                        </label>
                        <div className="col-sm-9">
                          <Select
                            id="input-type"
                            placeholder="Input type"
                            options={
                              dropDowns && dropDowns["type"]
                                ? dropDowns["type"]
                                : []
                            }
                            onChange={(selectedOption) =>
                              setRequirement({
                                ...requirement,
                                type: selectedOption,
                              })
                            }
                            value={requirement?.type || ""}
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- Text input with help --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="contact_person"
                          className="col-sm-3 form-label"
                        >
                          Info
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            id="contact_person"
                            type="text"
                            className="form-control"
                            placeholder="Info"
                            aria-describedby="contactHelp"
                            onChange={(e) =>
                              setRequirement({
                                ...requirement,
                                info: e.target.value,
                              })
                            }
                            value={requirement?.info || ""}
                            rows="12"
                          ></textarea>
                          <div id="contactHelp" className="form-text">
                            Enter details
                          </div>
                        </div>
                      </div>
                      {/* <!-- END : Text input with help --> */}
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default RequirementForm;
