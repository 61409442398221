import img1 from "../assets/img/profile-photos/1.png";
import img2 from "../assets/img/profile-photos/2.png";
import img3 from "../assets/img/profile-photos/3.png";
import img4 from "../assets/img/profile-photos/4.png";
import img5 from "../assets/img/profile-photos/5.png";
import img6 from "../assets/img/profile-photos/6.png";
import img7 from "../assets/img/profile-photos/7.png";

const avatar = [img1, img2, img3, img4, img5, img6, img7];

const useAvatar = (index = null) => {
  return index ? avatar[index] : avatar;
};

export default useAvatar;
