import React from "react";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";
import Page from "../layouts/Page";
import Sidebar from "../layouts/Sidebar";
import Loading from "../layouts/Loading"

const MainLayout = () => {
  return (
    <>
      <Page />
      <Header />
      <Navbar />
      <Sidebar />
      <Loading />
    </>
  );
};

export default MainLayout;
