import React, { useEffect, useState, useTransition } from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import useAvatar from "../../hooks/useAvatar";
import { FilterBar } from "../../widgets";

const UserList = () => {
  const { api, abortSignal, auth } = useApi();
  const [users, setUsers] = useState([]);
  const { Paginate, searchParamString } = usePagination();
  const avatar = useAvatar();

  useEffect(() => {
    const callApi = async () => {
      try {
        const { data } = await api.list(`/users${searchParamString}`);
        setUsers(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/users">Users</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Users List</h1>

          <p className="lead">List of of Users</p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/users/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            {users &&
              users.map((user, index) => (
                <div
                  className="col-sm-12 col-md-6 col-xl-4 col-xxl-3 mb-3"
                  key={user.id}
                >
                  {" "}
                  <div className="card">
                    <div className="card-body pt-2">
                      {/* <!-- Favorite button and option dropdown --> */}
                      <div className="d-flex justify-content-end gap-1">
                        <button
                          type="button"
                          className="btn btn-sm btn-icon btn-hover btn-white shadow-none text-orange add-tooltip"
                          data-bs-original-title="Remove from Favorites"
                        >
                          <i className="demo-psi-star fs-5"></i>
                        </button>
                        <div className="dropdown">
                          <button
                            className="btn btn-sm btn-icon btn-hover btn-light shadow-none"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="demo-pli-dot-horizontal fs-4"></i>
                            <span className="visually-hidden">
                              Toggle Dropdown
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <a href="#" className="dropdown-item">
                                <i className="demo-pli-pen-5 fs-5 me-2"></i>{" "}
                                Edit
                              </a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item text-danger">
                                <i className="demo-pli-recycling fs-5 me-2"></i>{" "}
                                Remove
                              </a>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">
                                <i className="demo-pli-mail fs-5 me-2"></i> Send
                                a Message
                              </a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">
                                <i className="demo-pli-calendar-4 fs-5 me-2"></i>{" "}
                                View Details
                              </a>
                            </li>
                            <li>
                              <a href="#" className="dropdown-item">
                                <i className="demo-pli-lock-user fs-5 me-2"></i>{" "}
                                Lock
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <!-- END : Favorite button and option dropdown --> */}

                      {/* <!-- Profile picture and short information --> */}
                      <div className="text-center position-relative">
                        <div className="pb-3">
                          <img
                            className="img-lg rounded-circle"
                            src={avatar[index % 7]}
                            alt="Profile Picture"
                            loading="lazy"
                          />
                        </div>
                        <Link
                          to={`${user.id}`}
                          className="h5 stretched-link btn-link"
                        >
                          {user?.name || "User"}
                        </Link>
                        <p className="text-muted">{user?.username || ""}</p>
                      </div>
                      <p className="text-center">
                        {user?.email || "User email is blank"}
                      </p>
                      {/* <!-- END : Profile picture and short information --> */}

                      {/* <!-- Social media buttons --> */}
                      <div className="mt-4 pt-3 text-center border-top text-muted">
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-primary text-inherit"
                        >
                          <i className="demo-psi-facebook fs-4"></i>
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-info text-inherit"
                        >
                          <i className="demo-psi-twitter fs-4"></i>
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-danger text-inherit"
                        >
                          <i className="demo-psi-google-plus fs-4"></i>
                        </a>
                        <a
                          href="#"
                          className="btn btn-icon btn-hover btn-warning text-inherit"
                        >
                          <i className="demo-psi-instagram fs-4"></i>
                        </a>
                      </div>
                      {/* <!-- END : Social media buttons --> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Paginate />
        </div>
      </div>
    </section>
  );
};

export default UserList;
