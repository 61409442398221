import React, { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useApi from "../../hooks/useApi";

const CheckLogin = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(true);

  const { api, abortSignal } = useApi();

  useEffect(() => {
    const refreshLogin = async () => {
      if (!auth?.user) {
        const refreshed = await api.isValidRefresh();
        setLoggedIn(refreshed);
      }
    };
    refreshLogin();
  }, [auth]);

  return loggedIn ? (
    auth.user ? (
      <Outlet />
    ) : (
      <div>Loading...</div>
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default CheckLogin;
