import { Routes, Route, Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "../context/AuthProvider";
import AppProvider from "../context/AppProvider";
import AuthLayout from "../layouts/AuthLayout";
import MainLayout from "../layouts/MainLayout";
import Missing from "../layouts/Missing";
import Login from "./auth/Login";
import CheckLogin from "./auth/CheckLogin";
import ForgotPassword from "./auth/ForgotPassword";
import UserForm from "./users/UserForm";
import UserList from "./users/UserList";
import UserView from "./users/UserView";
import Dashboard from "./users/Dashboard";
import Desktop from "./users/Desktop";
import Profile from "./users/Profile";

import ClientList from "./clients/ClientList";
import ClientForm from "./clients/ClientForm";
import ClientView from "./clients/ClientView";

import CompanyList from "./companies/CompanyList";
import CompanyForm from "./companies/CompanyForm";
import CompanyView from "./companies/CompanyView";

import CompanyTypeList from "./companies/CompanyTypeList";
import CompanyTypeView from "./companies/CompanyTypeView";

import BranchList from "./branches/BranchList";
import BranchForm from "./branches/BranchForm";
import BranchView from "./branches/BranchView";

import PolicyList from "./policies/PolicyList";
import PolicyForm from "./policies/PolicyForm";
import PolicyView from "./policies/PolicyView";

import PolicyTypeList from "./policy-types/PolicyTypeList";
import PolicyTypeForm from "./policy-types/PolicyTypeForm";
import PolicyTypeView from "./policy-types/PoliccyTypeView";
import PolicyTypeProductList from "./policy-types/PolicyTypeProductList";

import ProductList from "./products/ProductList";
import ProductForm from "./products/ProductForm";
import ProductView from "./products/ProductView";

import ClaimList from "./claims/ClaimList";

import RequirementList from "./policy-requirements/RequirementList";
import RequirementForm from "./policy-requirements/RequirementForm";

import {
  SmsMessaging,
  EmailMessaging,
  NotificationSetting,
  Notification,
} from "./";

function App() {
  const queryClient = new QueryClient();

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route element={<MainLayout />}>
              <Route element={<CheckLogin />}>
                <Route path="" element={<Desktop />} />
                <Route path="desktop" element={<Desktop />} />
                {/* <Route path="" element={<Home />} /> */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/change-password" element={<Desktop />} />

                <Route path="/clients" element={<ClientList />} />
                <Route path="/clients/add" element={<ClientForm />} />
                <Route path="/clients/:id" element={<ClientView />}>
                  <Route
                    path=""
                    element={<PolicyList mini={true} scope="/clients/" />}
                  />
                  <Route
                    path="policies"
                    element={<PolicyList mini={true} scope="/clients/" />}
                  />
                  <Route path="claims" element={<PolicyList mini={true} />} />
                </Route>
                <Route
                  path="/clients/:id/edit"
                  element={<ClientForm scope="edit" />}
                />

                <Route path="/companies" element={<CompanyList />} />
                {/* <Route path="/companies/list" element={<CompanyList />} /> */}
                <Route path="/companies/add" element={<CompanyForm />} />
                <Route path="/companies/:id" element={<CompanyView />}>
                  <Route path="" element={<BranchList />} />
                  <Route
                    path="products"
                    element={<ProductList scope="/companies/" />}
                  />
                  <Route
                    path="policies"
                    element={<PolicyList mini={true} scope="/companies/" />}
                  />
                  <Route
                    path="claims"
                    element={<BranchList scope="/companies/" />}
                  />
                  <Route
                    path="clients"
                    element={<ClientList scope="/companies/" />}
                  />
                  <Route path="branches/add" element={<BranchForm />} />
                </Route>
                <Route
                  path="/companies/:id/edit"
                  element={<CompanyForm scope="edit" />}
                />
                <Route path="/companies/types" element={<CompanyTypeList />} />
                <Route
                  path="/companies/types/:id"
                  element={<CompanyTypeView />}
                />
                <Route path="/branches/:id" element={<BranchView />}>
                  <Route
                    path=""
                    element={<PolicyList mini={true} scope="/branch/" />}
                  />
                  <Route
                    path="products"
                    element={<ProductList scope="/company/" />}
                  />
                  <Route
                    path="claims"
                    element={<BranchList scope="/company/" />}
                  />
                  <Route
                    path="clients"
                    element={<ClientList scope="/company/" />}
                  />
                  <Route path="edit" element={<BranchForm scope="edit" />} />
                </Route>

                <Route
                  path="/policy-requirements"
                  element={<RequirementList />}
                />
                <Route
                  path="/policy-requirements/add"
                  element={<RequirementForm />}
                />
                <Route
                  path="/policy-requirements/:id"
                  element={<RequirementForm />}
                />
                <Route
                  path="/policy-requirements/:id/edit"
                  element={<RequirementForm scope="edit" />}
                />

                <Route path="/policy-types" element={<PolicyTypeList />} />
                <Route path="/policy-types/add" element={<PolicyTypeForm />} />
                <Route path="/policy-types/:id" element={<PolicyTypeView />}>
                  <Route path="" element={<PolicyTypeProductList />} />
                </Route>
                <Route
                  path="/policy-types/:id/edit"
                  element={<PolicyTypeForm scope="edit" />}
                />

                <Route path="/products" element={<ProductList />} />
                <Route path="/products/add" element={<ProductForm />} />
                <Route path="/products/:id" element={<ProductView />} />
                <Route
                  path="/products/:id/edit"
                  element={<ProductForm scope="edit" />}
                />

                <Route path="/policies" element={<PolicyList />} />
                <Route path="/policies/add" element={<PolicyForm />} />
                <Route path="/policies/add/:target" element={<PolicyForm />} />
                <Route
                  path="/policies/add/:target/:target_id"
                  element={<PolicyForm />}
                />
                <Route path="/policies/:id" element={<PolicyView />} />
                <Route
                  path="/policies/:id/edit"
                  element={<PolicyForm scope="edit" />}
                />

                <Route path="/claims" element={<ClaimList />}>
                  <Route path="add" element={<PolicyForm />} />
                </Route>

                <Route path="/users" element={<UserList />} />
                <Route path="/users/add" element={<UserForm />} />
                <Route path="/users/:id" element={<UserView />} />
                <Route path="edit" element={<UserForm scope="edit" />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/notifications">
                  <Route path="" element={<Notification />} />
                  <Route path="settings" element={<NotificationSetting />} />
                  <Route path="sms-messaging" element={<SmsMessaging />} />
                  <Route path="email-messaging" element={<EmailMessaging />} />
                </Route>
              </Route>
            </Route>

            <Route path="/" element={<AuthLayout />}>
              <Route path="*" element={<Missing />} />
            </Route>
          </Routes>
        </AppProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
