import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useReload from "../../hooks/useReload";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const AddPolicyItem = (props) => {
  const [dropDowns, setDropDowns] = useState({ policyType: [] });
  const [requirements, setRequirements] = useState([]);
  const [productRequirements, setProductRequirements] = useState([]);
  const [policyTypeRequirements, setPolicyTypeRequirements] = useState([]);
  const [policyItemRequirement, setPolicyItemRequirement] = useState({});
  const [policyItem, setPolicyItem] = useState({});
  const { id } = useParams();
  const policyRef = useRef();
  const productRef = useRef();
  const [showButton, setShowButton] = useState(false);
  const { api, abortSignal, auth } = useApi();
  const { toggleReload } = useReload();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...policyItem,
        PolicyTypeId: policyItem?.PolicyTypeId?.value || "",
        CompanyId: policyItem?.CompanyId?.value || "",
        ProductId: policyItem?.ProductId?.value || "",
        policyRequirement: JSON.stringify(policyItemRequirement),
        PolicyId: id,
      };
      const response = await api.add("/policies/policy-items", data);
      if (response) {
        setProductRequirements([]);
        setPolicyTypeRequirements([]);
        setRequirements([]);
        setPolicyItemRequirement({});
        setPolicyItem({});
        setShowButton(false);
        toggleReload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDropDowns = async (models) => {
    try {
      const { data } = await api.list(`/dropdowns`, models);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const handlePolicyTypeChange = async (selectedOption) => {
    const id = selectedOption.value;
    setPolicyItem({
      ...policyItem,
      PolicyTypeId: selectedOption,
      ProductId: {},
    });
    try {
      const { data } = await api.list(
        `/policies/policy-types/${id}/requirements`
      );
      setPolicyTypeRequirements(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanyChange = async (selectedOption) => {
    const id = selectedOption.value;
    setPolicyItem({
      ...policyItem,
      CompanyId: selectedOption,
      ProductId: {},
    });
  };

  const handleProductChange = async (selectedOption) => {
    const id = selectedOption.value;
    setPolicyItem({
      ...policyItem,
      ProductId: selectedOption,
    });
    try {
      const { data } = await api.list(`/products/${id}/requirements`);
      setProductRequirements(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const productDropDown = async () => {
      try {
        const data = await getDropDowns([
          {
            product: {
              PolicyTypeId: policyItem.PolicyTypeId.value,
              CompanyId: policyItem.CompanyId.value,
            },
          },
        ]);
        setDropDowns({ ...dropDowns, ...data });
      } catch (error) {}
    };
    if (policyItem.PolicyTypeId && policyItem.CompanyId) {
      productDropDown();
      //   productRef.current.focus();
      setShowButton(true);
    }
  }, [policyItem.CompanyId, policyItem.PolicyTypeId]);

  useEffect(() => {
    let removeDups = [...policyTypeRequirements, ...productRequirements];
    removeDups = removeDups.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
    setRequirements([...removeDups]);
  }, [productRequirements, policyTypeRequirements]);

  useEffect(() => {
    const callDropDown = async () => {
      const data = await getDropDowns(["policyType", "company"]);
      setDropDowns(data);
    };
    callDropDown();
  }, [auth]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button
        onClick={handleShow}
        variant="info"
        className="hstack gap-2 mb-3 me-2"
      >
        <i className="demo-psi-add fs-4"></i>
        <span className="vr"></span>
        Add Policy
      </Button>
      <Link
        to="/policies"
        className="btn btn-outline-primary hstack gap-2 mb-3"
      >
        <i className="demo-psi-file fs-4"></i>
        <span className="vr"></span>
        Policies
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <form onSubmit={handleSave} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Add Individual Policy Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <label htmlFor="policy-type" className="col-sm-3 col-form-label">
                Policy Type
              </label>
              <div className="col-sm-9">
                <Select
                  id="policy-type"
                  ref={policyRef}
                  options={
                    dropDowns && dropDowns["policyType"]
                      ? dropDowns["policyType"]
                      : []
                  }
                  onChange={(selectedOption) =>
                    handlePolicyTypeChange(selectedOption)
                  }
                  value={policyItem?.PolicyTypeId || ""}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="company" className="col-sm-3 col-form-label">
                Underwriting Company
              </label>
              <div className="col-sm-9">
                <Select
                  id="company"
                  options={
                    dropDowns && dropDowns["company"]
                      ? dropDowns["company"]
                      : []
                  }
                  onChange={(selectedOption) =>
                    handleCompanyChange(selectedOption)
                  }
                  value={policyItem?.CompanyId || ""}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="product" className="col-sm-3 col-form-label">
                Product
              </label>
              <div className="col-sm-9">
                <Select
                  id="product"
                  ref={productRef}
                  options={
                    dropDowns && dropDowns["product"]
                      ? dropDowns["product"]
                      : []
                  }
                  onChange={(selectedOption) =>
                    handleProductChange(selectedOption)
                  }
                  value={policyItem?.ProductId || ""}
                  required
                />
              </div>
            </div>
            <div>
              {requirements && requirements.length > 0 && (
                <p className="border-bottom border-3 mt-2">
                  Policy requirements
                </p>
              )}
              {requirements &&
                requirements.map((element) => {
                  return (
                    <div className="row mb-3" key={element.id}>
                      <label
                        htmlFor={element.id}
                        className="col-sm-3 col-form-label"
                      >
                        {element.name}
                      </label>
                      <div className="col-sm-9">
                        {element.type === "check" ? (
                          <div className="form-check form-switch">
                            <input
                              id={element.id}
                              className="form-check-input mt-2"
                              type="checkbox"
                              onChange={(e) =>
                                setPolicyItemRequirement({
                                  ...policyItemRequirement,
                                  [element.id]: e.target.checked,
                                })
                              }
                            />
                          </div>
                        ) : element.type === "date" ? (
                          <DatePicker
                            id={element.id}
                            className="form-control"
                            selected={policyItemRequirement?.[element.id] || ""}
                            onChange={(date) =>
                              setPolicyItemRequirement({
                                ...policyItemRequirement,
                                [element.id]: date,
                              })
                            }
                            isClearable
                          />
                        ) : (
                          <input
                            className="form-control"
                            id={element.id}
                            onChange={(e) =>
                              setPolicyItemRequirement({
                                ...policyItemRequirement,
                                [element.id]: e.target.value,
                              })
                            }
                            value={
                              policyItemRequirement[element.id]
                                ? policyItemRequirement[element.id]
                                : ""
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {showButton && (
              <Button variant="primary" type="submit">
                Add
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddPolicyItem;
