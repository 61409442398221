import { createContext, useState } from "react";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [pages, setPages] = useState(0);
  const [paginationOptions, setPaginationOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  return (
    <AppContext.Provider
      value={{
        pagination: {
          paginationOptions,
          setPaginationOptions,
          pages,
          setPages,
        },
        app: {
          isLoading,
          setIsLoading,
        },
        page: {
          reload,
          setReload,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
