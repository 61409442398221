import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AppContext } from "../context/AppProvider";

const usePagination = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    paginationOptions: options,
    setPaginationOptions: setOptions,
    pages,
    setPages,
  } = useContext(AppContext).pagination;

  const [searchParamString, setSearchParamString] = useState("");

  const getOtherSearchParams = () => {
    let paramString = "";
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page") paramString += `${key}=${value}&`;
    }

    if (paramString) {
      paramString = "?" + paramString.trimEnd();
      // paramString = paramString.endsWith("&")
      //   ? paramString.slice(0, -1)
      //   : paramString;
    } else {
      paramString = "?";
    }

    return paramString;
  };
  const Paginate = () => {
    //get existing search parameters in url.
    //append pagination param to existing
    const otherSearchParams = getOtherSearchParams();

    let paramString = "";
    for (const [key, value] of searchParams.entries()) {
      paramString += `${key}=${value}&`;
    }

    if (paramString) {
      paramString = "?" + paramString.trimEnd();
      paramString = paramString.endsWith("&")
        ? paramString.slice(0, -1)
        : paramString;
    }

    const page = searchParams.get("page") || 1;

    const [currentPage, setCurrentPage] = useState(page);
    const limit = (options?.limit && options.limit) || 1;
    const lastPage = Math.ceil(pages / limit);

    useEffect(() => {
      setCurrentPage(page);
    }, [page, options]);

    useEffect(() => {
      setSearchParamString(paramString);
    }, [paramString]);

    return (
      pages > limit && (
        <nav className="text-align-center mt-5" aria-label="Table navigation">
          <ul className="pagination justify-content-center">
            <li
              className={currentPage == 1 ? "page-item disabled" : "page-item"}
            >
              <Link
                to={`${otherSearchParams}page=${parseInt(currentPage) - 1}`}
                className="page-link"
              >
                Previous
              </Link>
            </li>
            <li className="page-item1">
              <span className="page-link">Page</span>
            </li>
            <li className="page-item">
              <input
                type="number"
                className="form-control form-control-sm bg-transparent text-center border-start-0 border-end-0 rounded-0"
                placeholder="page"
                style={{ width: 90 }}
                value={currentPage}
                onChange={(e) => setCurrentPage(e.target.value)}
              />
            </li>
            <li className="">
              <span className="page-link">of {lastPage} pages</span>
            </li>
            {/* {Array(lastPage)
              .fill(0)
              .map((pageIndex, index) => (
                <li
                  className={
                    currentPage == index + 1 ? "page-item active" : "page-item"
                  }
                  key={index}
                >
                  <Link to={`?page=${index + 1}`} className="page-link">
                    {index + 1}
                  </Link>
                </li>
              ))} */}

            <li
              className={
                currentPage == lastPage ? "page-item disabled" : "page-item"
              }
            >
              <Link
                to={`${otherSearchParams}page=${parseInt(currentPage) + 1}`}
                className="page-link"
              >
                Next
              </Link>
            </li>
          </ul>
        </nav>
      )
    );
  };

  return { Paginate, setPages, searchParamString, setOptions };
};

export default usePagination;
