import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Missing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const from = location?.state?.from?.pathname || "/dashboard";

  const handleBack = async (e) => {
    navigate(from, { replace: true });
  };

  return (
    <>
      <div className="text-center">
        <div className="error-code page-title mb-3">404</div>
        <h3 className="mb-4">
          <div className="badge bg-info">Page not found !</div>
        </h3>
        <p className="lead">
          Sorry, but the page you are looking for has not been found on our
          server.
        </p>
      </div>

      {/* <!-- Action buttons --> */}
      <div className="d-flex justify-content-center gap-3 mt-4">
        <button type="button" onClick={handleBack} className="btn btn-light">
          Go back
        </button>
        <Link to="/dashboard" className="btn btn-primary">
          Return home
        </Link>
      </div>
      {/* <!-- END : Action buttons --> */}
    </>
  );
};

export default Missing;
