import React from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useApi from "../../hooks/useApi";

export const SmsMessaging = () => {
  const { api, abortSignal, auth } = useApi();

  const getTemplates = async () => {
    try {
      const url = `/notifications`;
      const { data } = await api.list(url);
      return data;
    } catch (error) {
      throw new Error("failed to fetch records");
    }
  };

  const { data: templates } = useQuery(["notifictionTemplates", auth], () =>
    getTemplates()
  );

  let templateContainter = [];
  if (templates) {
    for (const [key, value] of Object.entries(templates)) {
      templateContainter.push(
        <div className="accordion-item mt-1" key={key}>
          <div className="accordion-header" id="_dm-gapAccHeadingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#_dm-${key}`}
              aria-expanded="false"
              aria-controls={`_dm-${key}`}
            >
              {value.title}
            </button>
          </div>
          <div
            id={`_dm-${key}`}
            className="accordion-collapse collapse"
            aria-labelledby="_dm-gapAccHeadingOne"
            data-bs-parent="#_dm-gapAccordion"
          >
            <div className="accordion-body">
              <pre>
                <div className="px-0 mb-2">
                  <div className="d-flex justify-content-between">
                    <label
                      className="form-check-label h5 mb-0"
                      htmlFor="_dm-dbInvisibleMode"
                    >
                      Invisible Mode
                    </label>
                    <div className="form-check form-switch">
                      <input
                        id="_dm-dbInvisibleMode"
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                  <span>Nascetur ridiculus mus.</span>
                </div>
              </pre>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/notifications">Notifications</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                SMS Messaging
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">{"SMS messaging"}</h1>
          {/* <h2 className="h5">{"SMS messaging"}</h2> */}
          <p className="lead">SMS view</p>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            <div className="col-lg-8">
              <h5 className="mb-3">SMS Templates</h5>
              <div className="accordion" id="_dm-gapAccordion">
                {templateContainter}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SmsMessaging;
