import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import Content from "./Content";
import Footer from "./Footer";

const Page = () => {
  return <Outlet></Outlet>;
};

export default Page;
