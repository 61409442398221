import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useApi from "../../hooks/useApi";
import Select from "react-select";
import useApp from "../../hooks/useApp";

const ProductForm = (props) => {
  const { showAlert } = useApp();
  const [companyId, setCompanyId] = useState("");
  const [policyTypeId, setPolicyTypeId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const from = location?.state?.from;
  const { api, auth } = useApi();
  const nameRef = useRef();
  const { scope = "add" } = props;
  const queryClient = useQueryClient();

  const handleSave = useMutation(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      let data = { id };
      for (const pair of formData.entries()) {
        data[pair[0]] = pair[1];
      }
      if (scope === "edit") {
        return api.modify(`/products/${id}`, data);
      } else {
        return api.addNew("/products", data);
      }
    },
    {
      onSuccess: (data, variables, context) => {
        showAlert("Record saved", "success");
        queryClient.invalidateQueries(["product", id, auth]);
        navigate(-1, { replace: true });
      },
    }
  );

  const getDropDownsQuery = async (models) => {
    try {
      const { data } = await api.getAll(`/dropdowns`, models);
      return data;
    } catch (error) {
      throw new Error("failed to fetch dropdowns");
    }
  };

  const getClientById = (productId) => {
    return api.getOne(`/products/${productId}`);
  };

  const models = ["company", "policyType"];
  const { data: dropDowns } = useQuery(
    ["dropDowns", models, auth],
    ({ queryKey }) => getDropDownsQuery(queryKey[1])
  );

  const { data: product } = useQuery(
    ["product", id, auth],
    ({ queryKey }) => getClientById(queryKey[1]),
    {
      select: (data) =>
        data && {
          ...data,
          CompanyId: {
            value: data?.CompanyId,
            label: data?.Company?.name,
          },
          PolicyTypeId: {
            value: data?.PolicyTypeId,
            label: data?.PolicyType?.name,
          },
        },
    }
  );

  useEffect(() => {
    nameRef.current.focus();
  }, [id, auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} Product
          </h1>

          <p className="lead">
            {scope === "edit" ? "Edit" : "Add new"} Product
          </p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/products" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Products
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave.mutate}>
                    <div className="card-body">
                      {/* <!-- Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="name"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={nameRef}
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            defaultValue={product?.name || ""}
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- END : Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="policy-type"
                          className="col-sm-3 col-form-label"
                        >
                          Policy Type
                        </label>
                        <div className="col-sm-9">
                          <Select
                            id="policy-type"
                            name="PolicyTypeId"
                            placeholder="Policy type"
                            options={
                              dropDowns && dropDowns["policyType"]
                                ? dropDowns["policyType"]
                                : []
                            }
                            onChange={(selectedOption) =>
                              setPolicyTypeId(selectedOption)
                            }
                            value={policyTypeId || product?.PolicyTypeId}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="company"
                          className="col-sm-3 col-form-label"
                        >
                          Insurrance Company
                        </label>
                        <div className="col-sm-9">
                          <Select
                            id="company"
                            name="CompanyId"
                            placeholder="Company"
                            options={
                              dropDowns && dropDowns["company"]
                                ? dropDowns["company"]
                                : []
                            }
                            onChange={(selectedOption) =>
                              setCompanyId(selectedOption)
                            }
                            value={companyId || product?.CompanyId}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="commission-rate"
                          className="col-sm-3 col-form-label"
                        >
                          Commission Rate(%)
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            step="0.01"
                            min="0.01"
                            id="commission-rate"
                            name="commissionRate"
                            className="form-control"
                            defaultValue={product?.commissionRate}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Text input with help --> */}
                      <div className="row mb-3">
                        <label htmlFor="info" className="col-sm-3 form-label">
                          Info
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            id="info"
                            name="info"
                            type="text"
                            className="form-control"
                            placeholder="Info"
                            aria-describedby="contactHelp"
                            defaultValue={product?.info || ""}
                            rows="12"
                          ></textarea>
                          <div id="contactHelp" className="form-text">
                            Enter details of the product
                          </div>
                        </div>
                      </div>
                      {/* <!-- END : Text input with help --> */}
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ProductForm;
