import React from "react";
import { useNavigate } from "react-router-dom";
import useApi from "./useApi";

const useLogout = () => {
  const navigate = useNavigate();
  const { api } = useApi();

  const logout = async () => {
    try {
      const response = await api.logout();
      if (response) navigate("/");
    } catch (error) {}
  };

  return { logout };
};

export default useLogout;
