import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet, NavLink } from "react-router-dom";
import useApi from "../../hooks/useApi";

const CompanyView = () => {
  const [company, setCompany] = useState({});
  const [stats, setStats] = useState({});
  const { id } = useParams();
  const { policyCount, branchCount, clientCount, productCount } = stats;

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.read(`/companies/${id}`);
        if (response.success) {
          setCompany(response.data.info);
          setStats(response.data.stats);
        }
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [id, auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/companies">Insurrance Companies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {company?.name || id}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {company?.name || "Company detail"}
          </h1>

          <p className="lead">Detail view of company</p>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            <div className="col-xxl-9 col-xl-8 col-lg-7 col-sm-12">
              {<Outlet />}
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-5 col-sm-12">
              {/* <div className="row">
                <div className="col-12 col-xl-12"> */}
              <div className="row g-xl-1 mb-2">
                <div className="col-sm-3">
                  {/* <!--Tile--> */}
                  <NavLink to="./policies" className="btn-link">
                    <div className="card bg-primary text-white mb-3 mb-xl-1">
                      <div className="p-3 text-center">
                        <span className="display-7">{policyCount || 0}</span>
                        <p>Policy</p>
                        <i className="demo-psi-shopping-bag text-white text-opacity-50 fs-5"></i>
                      </div>
                    </div>
                  </NavLink>
                  {/* <!--END : Tile--> */}
                </div>
                <div className="col-sm-3">
                  {/* <!--Tile--> */}
                  <NavLink to="./" className="btn-link">
                    <div className="card bg-warning text-white mb-3 mb-xl-1">
                      <div className="p-3 text-center">
                        <span className="display-7">{branchCount || 0}</span>
                        <p>Branch</p>
                        <i className="demo-psi-mail text-white text-opacity-50 fs-5"></i>
                      </div>
                    </div>
                  </NavLink>

                  {/* <!--END : Tile--> */}
                </div>
                <div className="col-sm-3">
                  {/* <!--Tile--> */}
                  <NavLink to="./clients" className="btn-link">
                    <div className="card bg-success text-white mb-3 mb-xl-1">
                      <div className="p-3 text-center">
                        <span className="display-7">{clientCount || 0}</span>
                        <p>Client</p>
                        <i className="demo-psi-receipt-4 text-white text-opacity-50 fs-5"></i>
                      </div>
                    </div>
                  </NavLink>

                  {/* <!--END : Tile--> */}
                </div>
                <div className="col-sm-3">
                  {/* <!--Tile--> */}
                  <NavLink to="./products" className="btn-link">
                    <div className="card bg-info text-white mb-3 mb-xl-1">
                      <div className="p-3 text-center">
                        <span className="display-7">{productCount || 0}</span>
                        <p>Product</p>
                        <i className="demo-psi-receipt-4 text-white text-opacity-50 fs-5"></i>
                      </div>
                    </div>
                  </NavLink>
                  {/* <!--END : Tile--> */}
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
              {/* <!-- User widget with background color --> */}
              <div className="card bg-secondary text-white mb-3">
                <div className="card-body">
                  {/* <!-- Profile picture and short information --> */}
                  <div className="d-flex position-relative">
                    <div className="flex-grow-1">
                      <span className="h5">{company?.name || ""}</span>
                      {/* <p className="m-0"></p> */}
                    </div>
                    <div className="flex-grow-0">
                      <div className="text-center">
                        <div className="d-flex justify-content-end gap-3">
                          <Link to="edit" className="btn btn-sm btn-secondary">
                            <i className="d-block demo-psi-pen-5 fs-3 mb-2"></i>{" "}
                            Edit
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small className="">
                    <pre>{company?.contact_person || ""}</pre>
                  </small>
                  {/* <!-- END : Profile picture and short information --> */}
                </div>
                <div className="card-footer bg-secondary text-break">
                  <pre className="text-wrap">{company?.address || ""}</pre>
                </div>
              </div>
              {/* <!-- END : User widget with background color --> */}
              {/* <!-- Stat widget --> */}
              <div className="card bg-info text-white mb-3 mb-xl-3">
                <div className="card-body py-3">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0">
                      <i className="d-flex align-items-center justify-content-center demo-pli-male-female display-6"></i>
                    </div>
                    <div className="flex-grow-1 ms-4">
                      <h5 className="h2 mb-0">314,675</h5>
                      <p className="text-white text-opacity-50 mb-0">
                        Total clients
                      </p>
                    </div>
                  </div>

                  <div className="progress progress-md mb-2">
                    <div
                      className="progress-bar bg-white"
                      role="progressbar"
                      style={{ width: "55%" }}
                      aria-valuenow="55"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>

                  <p className="text-white text-opacity-75 mb-0">
                    <strong>5%</strong> Higher than last year{" "}
                  </p>
                </div>
              </div>
              {/* <!-- END : Stat widget --> */}

              {/* <!-- Stat widget --> */}
              <div className="card bg-primary text-white mb-3 mb-xl-3">
                <div className="card-body py-3">
                  <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0">
                      <i className="d-flex align-items-center justify-content-center demo-pli-add-cart display-5"></i>
                    </div>
                    <div className="flex-grow-1 ms-4">
                      <h5 className="h2 mb-0">5,345</h5>
                      <p className="text-white text-opacity-50 mb-0">
                        Policies Sold
                      </p>
                    </div>
                  </div>

                  <div className="progress progress-md mb-2">
                    <div
                      className="progress-bar bg-white"
                      role="progressbar"
                      style={{ width: "91%" }}
                      aria-valuenow="91"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>

                  <p className="text-white text-opacity-75 mb-0">
                    <strong>954</strong> Policies sold this year
                  </p>
                </div>
              </div>
              {/* <!-- END : Stat widget --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyView;
