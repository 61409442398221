import React from "react";
import { Link, NavLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import profileImage from "../assets/img/profile-photos/1.png";

function Navbar() {
  const { auth } = useAuth();
  const { logout } = useLogout();
  const toggleSubMenu = (e) =>
    e.target.parentNode
      .querySelector(".mininav-content")
      .classList.toggle("collapse");

  return auth.user ? (
    <nav id="mainnav-container" className="mainnav">
      <div className="mainnav__inner">
        {/* <!-- Navigation menu --> */}
        <div className="mainnav__top-content scrollable-content pb-5">
          {/* <!-- Profile Widget --> */}
          <div className="mainnav__profile mt-3 d-flex3">
            <div className="mt-2 d-mn-max"></div>

            {/* <!-- Profile picture  --> */}
            <div className="mininav-toggle text-center py-2">
              <img
                className="mainnav__avatar img-md rounded-circle border"
                src={profileImage}
                alt="Profile Picture"
              />
            </div>

            <div className="mininav-content collapse d-mn-max">
              <div className="d-grid">
                {/* <!-- User name and position --> */}
                <button
                  className="d-block btn shadow-none p-2"
                  data-bs-toggle="collapse"
                  data-bs-target="#usernav"
                  aria-expanded="false"
                  aria-controls="usernav"
                >
                  <span className="dropdown-toggle d-flex justify-content-center align-items-center">
                    <h6 className="mb-0 me-3">{auth?.user?.name || ""}</h6>
                  </span>
                  <small className="text-muted">
                    {auth?.user?.username || ""}
                  </small>
                </button>

                {/* <!-- Collapsed user menu --> */}
                <div id="usernav" className="nav flex-column collapse">
                  {/* <a
                    href="#"
                    className="nav-link d-flex justify-content-between align-items-center"
                  >
                    <span>
                      <i className="demo-pli-mail fs-5 me-2"></i>
                      <span className="ms-1">Messages</span>
                    </span>
                    <span className="badge bg-danger rounded-pill">14</span>
                  </a>
                  <a href="#" className="nav-link">
                    <i className="demo-pli-male fs-5 me-2"></i>
                    <span className="ms-1">Profile</span>
                  </a>
                  <a href="#" className="nav-link">
                    <i className="demo-pli-gear fs-5 me-2"></i>
                    <span className="ms-1">Settings</span>
                  </a>
                  <a href="#" className="nav-link">
                    <i className="demo-pli-computer-secure fs-5 me-2"></i>
                    <span className="ms-1">Lock screen</span>
                  </a> */}
                  <span onClick={logout} className="nav-link">
                    <i className="demo-pli-unlock fs-5 me-2"></i>
                    <span className="ms-1">Logout</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End - Profile widget --> */}

          {/* <!-- Navigation Category --> */}
          <div className="mainnav__categoriy py-3">
            <h6 className="mainnav__caption mt-0 px-3 fw-bold">Navigation</h6>
            <ul className="mainnav__menu nav flex-column">
              {/* <!-- Link with submenu --> */}
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : " nav-link"
                  }
                >
                  <i className="demo-pli-home fs-5 me-2"></i>
                  <span className="nav-label ms-1">Dashboard</span>
                </NavLink>
              </li>
              {/* <!-- END : Link with submenu --> */}

              {/* <!-- Link with submenu --> */}
              <li className="nav-item">
                <NavLink
                  to="/desktop"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : " nav-link"
                  }
                >
                  <i className="demo-pli-split-vertical-2 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Desktop</span>
                </NavLink>
              </li>
              {/* <!-- END : Link with submenu --> */}
            </ul>
          </div>
          {/* <!-- END : Navigation Category --> */}

          <div className="mainnav__categoriy py-3">
            <h6 className="mainnav__caption mt-0 px-3 fw-bold">App</h6>
            <ul className="mainnav__menu nav flex-column">
              {/* <!-- Link with submenu --> */}
              <li className="nav-item has-sub">
                <NavLink
                  to="/clients"
                  className={({ isActive }) =>
                    isActive
                      ? "mininav-toggle nav-link active"
                      : "mininav-toggle nav-link collapsed"
                  }
                  onClick={toggleSubMenu}
                >
                  <i className="demo-pli-boot-2 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Clients</span>
                </NavLink>
                <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <NavLink to="/clients/add" className="nav-link">
                      Add New
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/policies"
                  className={({ isActive }) =>
                    isActive
                      ? "mininav-toggle nav-link active"
                      : "mininav-toggle nav-link collapsed"
                  }
                  // onClick={toggleSubMenu}
                >
                  <i className="demo-pli-pen-5 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Policies</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/claims"
                  className={({ isActive }) =>
                    isActive
                      ? "mininav-toggle nav-link active"
                      : "mininav-toggle nav-link collapsed"
                  }
                >
                  <i className="demo-pli-speech-bubble-5 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Claims</span>
                </NavLink>
              </li>
              {/* <!-- END : Link with submenu --> */}

              {/* <!-- Link with submenu --> */}
              <li className="nav-item has-sub">
                <NavLink
                  to="/companies"
                  className={({ isActive }) =>
                    isActive
                      ? "mininav-toggle nav-link active"
                      : "mininav-toggle nav-link collapsed"
                  }
                  onClick={toggleSubMenu}
                >
                  <i className="demo-pli-receipt-4 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Companies</span>
                </NavLink>

                {/* <!-- Tables submenu list --> */}
                <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <NavLink to="/companies/add" className="nav-link">
                      Add New
                    </NavLink>
                  </li>
                </ul>
                {/* <!-- END : Tables submenu list --> */}
              </li>
              {/* <!-- END : Link with submenu --> */}
            </ul>
          </div>

          <div className="mainnav__categoriy py-3">
            <h6 className="mainnav__caption mt-0 px-3 fw-bold">Settings</h6>
            <ul className="mainnav__menu nav flex-column">
              <li className="nav-item has-sub">
                <NavLink
                  to="#"
                  className={({ isActive }) =>
                    isActive
                      ? "mininav-toggle nav-link"
                      : "mininav-toggle nav-link collapsed"
                  }
                  onClick={toggleSubMenu}
                >
                  <i className="demo-pli-computer-secure fs-5 me-2"></i>
                  <span className="nav-label ms-1">Configurations</span>
                </NavLink>
                <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <Link to="/companies/types" className="nav-link">
                      Company Type
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/policy-types" className="nav-link">
                      Policy Type
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/policy-requirements" className="nav-link">
                      Policy Requirements
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/users" className="nav-link">
                      Users
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/products" className="nav-link">
                      Products
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item has-sub">
                <NavLink
                  to="#"
                  className={({ isActive }) =>
                    isActive
                      ? "mininav-toggle nav-link"
                      : "mininav-toggle nav-link collapsed"
                  }
                  onClick={toggleSubMenu}
                >
                  <i className="demo-pli-mail fs-5 me-2"></i>
                  <span className="nav-label ms-1">Notification Manager</span>
                </NavLink>
                <ul className="mininav-content nav collapse">
                  {/* <li className="nav-item">
                    <Link
                      to="/notifications/sms-messaging"
                      className="nav-link"
                    >
                      SMS Messaging
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/notifications/email-messaging"
                      className="nav-link"
                    >
                      Email Messaging
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/notifications/settings" className="nav-link">
                      Settings
                    </Link>
                  </li> */}
                </ul>
              </li>

              <li className="nav-item has-sub">
                {/* <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-speech-bubble-5 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Blog Apps</span>
                </a> */}

                {/* <!-- Blog Apps submenu list --> */}
                {/* <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a href="./blog-apps-blog.html" className="nav-link">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./blog-apps-blog-list.html" className="nav-link">
                      Blog List
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./blog-apps-blog-list-2.html" className="nav-link">
                      Blog List 2
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./blog-apps-blog-article.html"
                      className="nav-link"
                    >
                      Blog Article
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./blog-apps-manage-posts.html"
                      className="nav-link"
                    >
                      Manage Posts
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./blog-apps-add-edit-posts.html"
                      className="nav-link"
                    >
                      Add Edit Posts
                    </a>
                  </li>
                </ul> */}
                {/* <!-- END : Blog Apps submenu list --> */}
              </li>
              {/* <!-- END : Link with submenu --> */}

              {/* <!-- Link with submenu --> */}
              {/* <li className="nav-item has-sub">
                <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-mail fs-5 me-2"></i>
                  <span className="nav-label ms-1">Email</span>
                </a> */}

              {/* <!-- Email submenu list --> */}
              {/* <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a href="./email-inbox.html" className="nav-link">
                      Inbox
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./email-view-message.html" className="nav-link">
                      View Message
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./email-compose-message.html" className="nav-link">
                      Compose Message
                    </a>
                  </li>
                </ul> */}
              {/* <!-- END : Email submenu list --> */}
              {/* </li> */}
              {/* <!-- END : Link with submenu --> */}

              {/* <!-- Link with submenu --> */}
              {/* <li className="nav-item has-sub">
                <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-file-html fs-5 me-2"></i>
                  <span className="nav-label ms-1">Other Pages</span>
                </a> */}

              {/* <!-- Other Pages submenu list --> */}
              {/* <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a
                      href="./other-pages-blank-page.html"
                      className="nav-link"
                    >
                      Blank Page
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./other-pages-invoice.html" className="nav-link">
                      Invoice
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./other-pages-search-results.html"
                      className="nav-link"
                    >
                      Search Results
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./other-pages-faq.html" className="nav-link">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./other-pages-pricing-tables.html"
                      className="nav-link"
                    >
                      Pricing Tables
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./other-pages-error-404.html" className="nav-link">
                      Error 404
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./other-pages-error-500.html" className="nav-link">
                      Error 500
                    </a>
                  </li>
                </ul> */}
              {/* <!-- END : Other Pages submenu list --> */}
              {/* </li> */}
              {/* <!-- END : Link with submenu --> */}

              {/* <!-- Link with submenu --> */}
              {/* <li className="nav-item has-sub">
                <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-window-2 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Front Pages</span>
                </a> */}

              {/* <!-- Front Pages submenu list --> */}
              {/* <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a href="./front-pages-error-404.html" className="nav-link">
                      Error 404
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./front-pages-error-500.html" className="nav-link">
                      Error 500
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./front-pages-maintenance.html"
                      className="nav-link"
                    >
                      Maintenance
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./front-pages-login.html" className="nav-link">
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="./front-pages-register.html" className="nav-link">
                      Register
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./front-pages-password-reminder.html"
                      className="nav-link"
                    >
                      Password Reminder
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./front-pages-lock-screen.html"
                      className="nav-link"
                    >
                      Lock Screen
                    </a>
                  </li>
                </ul> */}
              {/* <!-- END : Front Pages submenu list --> */}
              {/* </li> */}
              {/* <!-- END : Link with submenu --> */}

              {/* <!-- Link with submenu --> */}
              {/* <li className="nav-item has-sub">
                <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-tactic fs-5 me-2"></i>
                  <span className="nav-label ms-1">Menu Levels</span>
                </a>
                <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Menu Link
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Menu Link
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Menu Link
                    </a>
                  </li>
                  <li className="nav-item has-sub">
                    <a href="#" className="mininav-toggle nav-link collapsed">
                      Submenu
                    </a>
                    <ul className="mininav-content nav collapse">
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item has-sub">
                    <a href="#" className="mininav-toggle nav-link collapsed">
                      Submenu
                    </a>
                    <ul className="mininav-content nav collapse">
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="#" className="nav-link">
                          Menu Link
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
              {/* <!-- END : Link with submenu --> */}
            </ul>
          </div>
          {/* <!-- END : More Category --> */}

          {/* <!-- Extras Category --> */}
          {/* <div className="mainnav__categoriy py-3">
            <h6 className="mainnav__caption mt-0 px-3 fw-bold">Extras</h6>
            <ul className="mainnav__menu nav flex-column">
              <li className="nav-item has-sub">
                <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-happy fs-5 me-2"></i>
                  <span className="nav-label ms-1">Icons Pack</span>
                </a>
                <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a href="./icons-pack-ionicons.html" className="nav-link">
                      Ionicons
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./icons-pack-themify-icons.html"
                      className="nav-link"
                    >
                      Themify Icons
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./icons-pack-flag-icon-css.html"
                      className="nav-link"
                    >
                      Flag Icon CSS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./icons-pack-weather-icons.html"
                      className="nav-link"
                    >
                      Weather Icons
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item has-sub">
                <a href="#" className="mininav-toggle nav-link collapsed">
                  <i className="demo-pli-medal-2 fs-5 me-2"></i>
                  <span className="nav-label ms-1">Premium Icons</span>
                </a>
                <ul className="mininav-content nav collapse">
                  <li className="nav-item">
                    <a
                      href="./premium-icons-line-icons-pack.html"
                      className="nav-link"
                    >
                      Line Icons Pack
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="./premium-icons-solid-icons-pack.html"
                      className="nav-link"
                    >
                      Solid Icons Pack
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div> */}
          {/* <!-- END : Extras Category --> */}

          {/* <!-- Widget --> */}
          {/* <div className="mainnav__profile">
            <div className="mininav-toggle text-center py-2 d-mn-min">
              <i className="demo-pli-monitor-2"></i>
            </div>

            <div className="d-mn-max mt-5"></div>
            <div className="mininav-content collapse d-mn-max">
              <h6 className="mainnav__caption px-3 fw-bold">Server Status</h6>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item text-reset">
                  <div className="d-flex justify-content-between align-items-start">
                    <p className="mb-2 me-auto">CPU Usage</p>
                    <span className="badge bg-info rounded">35%</span>
                  </div>
                  <div className="progress progress-md">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: "35%" }}
                      aria-label="CPU Progress"
                      aria-valuenow="35"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
                <li className="list-group-item text-reset">
                  <div className="d-flex justify-content-between align-items-start">
                    <p className="mb-2 me-auto">Bandwidth</p>
                    <span className="badge bg-warning rounded">73%</span>
                  </div>
                  <div className="progress progress-md">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "73%" }}
                      aria-label="Bandwidth Progress"
                      aria-valuenow="73"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </li>
              </ul>
              <div className="d-grid px-3 mt-3">
                <a href="#" className="btn btn-sm btn-success">
                  View Details
                </a>
              </div>
            </div>
          </div> */}
        </div>
        <div className="mainnav__bottom-content border-top pb-2">
          <ul id="mainnav" className="mainnav__menu nav flex-column">
            <li className="nav-item has-sub">
              <span
                onClick={logout}
                className="nav-link mininav-toggle collapsed"
                aria-expanded="false"
              >
                <i className="demo-pli-unlock fs-5 me-2"></i>
                <span className="nav-label ms-1">Logout</span>
              </span>
              <ul className="mininav-content nav flex-column collapse">
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    This device only
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    All Devices
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link disabled"
                    href="#"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    Lock screen
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  ) : (
    <nav></nav>
  );
}

export default Navbar;
