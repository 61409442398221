import React, { useEffect, useState, useTransition } from "react";
import moment from "moment";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const RequirementListHeader = (props) => {
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">Policies</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policy-requirements">Requirements</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Requirements List</h1>

          <p className="lead">List of Requirements for policies</p>

          <div className="d-md-flex align-items-baseline my-3">
            <Link to="./add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">{props.children}</div>
      </div>
    </section>
  );
};

const RequirementListMain = (props) => {
  const { api, abortSignal, auth } = useApi();
  const [requirements, setRequirements] = useState([]);
  const { id } = useParams();

  const { Paginate, searchParamString } = usePagination();

  useEffect(() => {
    const callApi = async () => {
      try {
        const { data } = await api.list(
          `/policies/requirements/${searchParamString}`
        );
        setRequirements(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);
  return (
    <>
      {!props?.headless && (
        <>
          <h2 className="mb-0 mt-2">Products</h2>
          <div className="d-md-flex align-items-baseline my-3">
            <Link to="add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <div className="d-flex align-items-center gap-1 text-nowrap ms-auto mb-3">
              <span className="d-none d-md-inline-block me-2">Sort by : </span>
              <select className="d-inline-block w-auto form-select">
                <option value="date-created">Date Created</option>
                <option value="date-modified">Date Modified</option>
                <option value="frequency-used">Frequency Used</option>
                <option value="alpabetically">Alpabetically</option>
                <option value="alpabetically-reversed">
                  Alpabetically Reversed
                </option>
              </select>

              <button type="button" className="btn btn-light">
                Filter
              </button>
              <button type="button" className="btn btn-light btn-icon">
                <i className="demo-pli-gear fs-5"></i>
              </button>
            </div>
          </div>
        </>
      )}
      <div className="table-responsive bg-white">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Info</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {requirements &&
              requirements?.map((requirement) => (
                <tr key={requirement.id}>
                  <td>
                    <Link to={`${requirement.id}/edit`} className="btn-link">
                      {requirement?.name || ""}
                    </Link>
                  </td>
                  <td className="">
                    <p className="text-break">{requirement?.info || ""}</p>
                  </td>
                  <td>
                    <span className="text-muted">
                      <span className="p-end-2">{requirement?.type || ""}</span>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

const RequirementList = (props) => {
  return props?.scope ? (
    <RequirementListMain />
  ) : (
    <RequirementListHeader>
      <RequirementListMain headless={true} />
    </RequirementListHeader>
  );
};

export default RequirementList;
