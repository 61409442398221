import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import useApi from "../../hooks/useApi";
import useReload from "../../hooks/useReload";
import { FilterBar } from "../../widgets";

const CompanyTypeList = (props) => {
  const [companyTypes, setCompanyTypes] = useState([]);
  const [companyType, setCompanyType] = useState({});
  const { reload, toggleReload } = useReload();
  const nameRef = useRef();

  const { api, abortSignal, auth } = useApi();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (!companyType) {
        return;
      }
      const response = await api.add("/company-types", companyType);
      if (response.success) {
        toggleReload();
        setCompanyType({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const { data } = await api.list(`/company-types/`);
        setCompanyTypes(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth, reload]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/companies">Insurrance Companies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                type
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Insurrance Company Types</h1>

          <p className="lead">Insurrance Company types</p>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row">
            <div className="col-xxl-7 col-xl-8 col-lg-7 col-sm-12">
              {/* <PolicyItemList items={policy.PolicyItems} /> */}

              {/* list here */}
              <div className="d-md-flex align-items-baseline mt-3">
                <FilterBar />
              </div>
              <div className="table-responsive bg-white mb-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyTypes &&
                      companyTypes.map((companyType) => (
                        <tr key={companyType.id}>
                          <td>{companyType?.name}</td>
                          <td>
                            <pre>{companyType?.createdAt}</pre>
                          </td>
                          <td className="text-center">
                            <div className="d-flex flex-wrap justify-content-center">
                              <div className="btn-group">
                                <button
                                  title="Delete record"
                                  type="button"
                                  className="btn btn-icon btn-outline-primary"
                                >
                                  <i className="demo-psi-recycling fs-5 text-default"></i>
                                </button>
                                <button
                                  title="Edit record"
                                  type="button"
                                  className="btn btn-icon btn-outline-primary"
                                >
                                  <i className="demo-psi-tag icon-lg fs-5 text-success"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-4 col-lg-5 col-sm-12">
              <div className="card mb-3">
                <div className="card-header">
                  <h2>Add Insurrance Company Type</h2>
                </div>
                <form onSubmit={handleSave}>
                  <div className="card-body">
                    <div className="mb-3">
                      <span>Name</span>
                      <input
                        ref={nameRef}
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          setCompanyType({
                            ...companyType,
                            name: e.target?.value?.trim(),
                          })
                        }
                        value={companyType?.name || ""}
                        required
                      />
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="btn btn-primary btn-lg" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyTypeList;
