import React, { useContext } from "react";
import { AppContext } from "../context/AppProvider";

const useReload = () => {
  const { reload, setReload } = useContext(AppContext).page;

  const toggleReload = () => {
    setReload(!reload);
  };
  return { reload, toggleReload };
};

export default useReload;
