import React, { useEffect, useState, useTransition } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const CompanyList = () => {
  const { api, abortSignal, auth } = useApi();
  const [companies, setCompanies] = useState([]);

  const { Paginate, setPages, searchParamString, setOptions } = usePagination();

  useEffect(() => {
    const callApi = async () => {
      try {
        const { data } = await api.list(`/companies${searchParamString}`);
        setCompanies(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/companies">Insurrance Companies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Companies List</h1>

          <p className="lead">List of insurrance companies</p>

          <div className="d-md-flex align-items-baseline my-3">
            <Link
              to="/companies/add"
              className="btn btn-info hstack gap-2 mb-3"
            >
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">
          <div className="row table-responsive bg-white">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Type</th>
                  <th>Date added</th>
                  <th className="text-center">Status</th>
                  {/* <th className="text-center">action</th> */}
                </tr>
              </thead>
              <tbody>
                {companies &&
                  companies?.map((company) => (
                    <tr key={company.id}>
                      <td>
                        <Link to={company.id} className="btn-link">
                          {company.name}
                        </Link>
                      </td>
                      <td>
                        <pre>{company.contact_person}</pre>
                      </td>
                      <td>
                        <span className="text-primary">
                          {company?.CompanyType?.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          <i className="demo-pli-clock"></i>{" "}
                          <span className="p-end-2">
                            {moment(company.createdAt).format("DD/MM/YYYY")}
                          </span>
                        </span>
                      </td>
                      <td className="text-center fs-5">
                        <div className="badge bg-success">Active</div>
                      </td>
                      {/* <td className="text-center">-</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
            <Paginate />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyList;
