import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import useApi from "../../hooks/useApi";

const BranchForm = (props) => {
  const context = useOutletContext({});
  let reload = false;
  let setReload = (reload) => {};
  if (context) {
    reload = context.reload;
    setReload = context.setReload;
  }
  const [branch, setBranch] = useState({});
  const { id } = useParams();
  const { api, abortSignal, auth } = useApi();
  const nameRef = useRef();
  const { scope = "add" } = props;
  const navigate = useNavigate();
  // const { searchParamString } = usePagination();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      let response =
        scope === "edit"
          ? await api.edit(`/companies/branches/${id}`, branch)
          : await api.add(`/companies/${id}/branches`, branch);
      if (response.success) {
        if (scope === "edit") {
          navigate("..", { replace: true });
          setReload(!reload);
        } else {
          navigate(`/branches/${response.data.id}`, {
            replace: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scope === "add" && setBranch({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/companies/branches/${id}`);
        if (response.success) setBranch(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [id, auth]);

  return (
    <section>
      <div className="row">
        <div className="d-md-flex align-items-baseline mt-3">
          <Link to=".." className="btn btn-info hstack gap-2 mb-3">
            <i className="demo-psi-file fs-4"></i>
            <span className="vr"></span>
            View Branches
          </Link>
        </div>
        <div className="col-12 mb-3">
          <div className="card h-100">
            <form onSubmit={handleSave} autoComplete="off">
              <div className="card-body">
                {/* <!-- Text input --> */}
                <div className="row mb-3">
                  <label htmlFor="name" className="col-sm-3 col-form-label">
                    Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      ref={nameRef}
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder="Branch name"
                      onChange={(e) =>
                        setBranch({ ...branch, name: e.target.value })
                      }
                      value={branch?.name || ""}
                    />
                  </div>
                </div>
                {/* <!-- END : Text input --> */}

                {/* <!-- Text input with help --> */}
                <div className="row mb-3">
                  <label
                    htmlFor="contact_person"
                    className="col-sm-3 form-label"
                  >
                    Contact Person
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      id="contact_person"
                      type="text"
                      className="form-control"
                      placeholder="Contact Person"
                      aria-describedby="contactHelp"
                      rows="5"
                      onChange={(e) =>
                        setBranch({
                          ...branch,
                          contact_person: e.target.value,
                        })
                      }
                      value={branch?.contact_person || ""}
                    ></textarea>
                    <div id="contactHelp" className="form-text">
                      Enter details of branch contact person
                    </div>
                  </div>
                </div>
                {/* <!-- END : Text input with help --> */}

                {/* <!-- Textarea --> */}
                <div className="row mb-3">
                  <label htmlFor="address" className="col-sm-3 col-form-label">
                    Location
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      id="location"
                      className="form-control"
                      placeholder="Location/Address"
                      rows="7"
                      onChange={(e) =>
                        setBranch({
                          ...branch,
                          location: e.target.value,
                        })
                      }
                      value={branch?.location || ""}
                    ></textarea>
                  </div>
                </div>
                {/* <!-- END : Textarea --> */}
              </div>
              <div className="card-footer">
                <button className="btn btn-primary d-flex" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BranchForm;
