import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import useReload from "../../hooks/useReload";
import { FilterBar } from "../../widgets";

const PolicyList = (props) => {
  const { scope = "" } = props;
  return props?.mini ? (
    <PolicyListMini scope={scope} />
  ) : (
    <PolicyListHead>
      <PolicyListMini headless={true} scope={scope} />
    </PolicyListHead>
  );
};

const PolicyListHead = (props) => {
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policies">Policies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Policies List</h1>

          <p className="lead">List of all policies with insurrance companies</p>

          <div className="d-md-flex align-items-baseline my-3">
            <Link to="/policies/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add Policy
            </Link>
            <FilterBar />
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">{props.children}</div>
      </div>
    </section>
  );
};

const PolicyListMini = (props) => {
  const { reload } = useReload();
  const { id = "" } = useParams();
  const { scope = "" } = props;
  const { Paginate, searchParamString } = usePagination();
  const { api, abortSignal, auth } = useApi();
  const navigate = useNavigate();

  const getPolicies = async (searchString) => {
    try {
      const url = `/policies${scope}${id}${searchString}`;
      const { data } = await api.list(url);
      return data;
    } catch (error) {
      throw new Error("failed to fetch records");
    }
  };

  const { data: policies } = useQuery(
    ["policies", searchParamString, auth],
    ({ queryKey }) => getPolicies(queryKey[1])
  );

  let clientLink = (scope === "/clients/" && (id ? `${scope}${id}` : "")) || "";

  return (
    <>
      {!props?.headless && (
        <>
          <h2 className="mb-0 mt-2">Policies</h2>
          <div className="d-md-flex align-items-baseline mt-3">
            <Link
              to={`/policies/add${clientLink}`}
              className="btn btn-info hstack gap-2 mb-3"
            >
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add Policy
            </Link>

            <FilterBar />
          </div>
        </>
      )}
      <div className="table-responsive bg-white">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Client</th>
              <th>Items</th>
              <th>Created</th>
              <th>Info</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {policies &&
              policies.map((policy) => (
                <tr key={policy.id}>
                  <td>
                    <Link to={`/policies/${policy.id}`} className="btn-link">
                      {policy.Client.name}
                    </Link>
                  </td>
                  <td>
                    <span className="fw-bold">{policy.itemsCount}</span>
                  </td>
                  <td>
                    <pre>{policy.createdAt}</pre>
                  </td>
                  <td>
                    <span className="text-muted">
                      <i className="demo-pli-pin"></i> {policy.info}
                    </span>
                  </td>
                  <td className="text-center fs-5">
                    {policy.status === "running" ? (
                      <div className="badge bg-success">{policy.status}</div>
                    ) : policy.itemsCount > 0 ? (
                      <div className="badge bg-warning">pending</div>
                    ) : (
                      <div className="badge bg-info">{policy.status}</div>
                    )}
                  </td>
                  {/* <td className="text-center">-</td> */}
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

export default PolicyList;
