import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";

const UserForm = (props) => {
  const [user, setUser] = useState({});
  const { api, abortSignal, auth } = useApi();
  const { id } = useParams();
  const nameRef = useRef();
  const { scope = "add" } = props;
  const navigate = useNavigate();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const response =
        scope === "edit"
          ? await api.edit(`/users/${id}`, user)
          : await api.add("/users", user);
      if (response.success)
        navigate(`/users/${id ? id : response.data.id}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scope === "add" && setUser({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/clients/${id}`);
        if (response.success) setUser(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/users">User</Link>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}
          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} User
          </h1>

          <p className="lead">
            {scope === "edit" ? "Edit" : "Add new"} User record
          </p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/users" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Users
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave} autoComplete="off">
                    <div className="card-body">
                      <h5 className="card-title">Personal Data</h5>

                      <div className="row mb-3">
                        <label htmlFor="name" className="col-sm-3 form-label">
                          Name
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              ref={nameRef}
                              id="name"
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              required
                              onChange={(e) =>
                                setUser({ ...user, name: e.target.value })
                              }
                              value={user?.name || ""}
                            />
                            <label htmlFor="name">Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="phonenumber"
                          className="col-sm-3 form-label"
                        >
                          Phone Number
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              id="phonenumber"
                              type="text"
                              className="form-control"
                              placeholder="Phone number"
                              required
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  username: e.target.value,
                                })
                              }
                              value={user?.username || ""}
                            />
                            <label htmlFor="phonenumber">Phone number</label>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="email" className="col-sm-3 form-label">
                          Email
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              onChange={(e) =>
                                setUser({ ...user, email: e.target.value })
                              }
                              value={user?.email || ""}
                            />
                            <label htmlFor="email">Email</label>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="password"
                          className="col-sm-3 form-label"
                        >
                          Password
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              id="password"
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              required
                              onChange={(e) =>
                                setUser({ ...user, password: e.target.value })
                              }
                              value={user?.password || ""}
                            />
                            <label htmlFor="password">Password</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default UserForm;
