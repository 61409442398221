import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";

const ClientForm = (props) => {
  const [client, setClient] = useState({});
  const { api, abortSignal, auth } = useApi();
  const { id } = useParams();
  const nameRef = useRef();
  const { scope = "add" } = props;
  const navigate = useNavigate();

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const clientData = new FormData();
      for (const [key, value] of Object.entries(client)) {
        clientData.append(key, value);
      }
      const response =
        scope === "edit"
          ? await api.edit(`/clients/${id}`, clientData)
          : await api.add("/clients", clientData);
      if (response.success)
        navigate(`/clients/${id ? id : response.data.id}`, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scope === "add" && setClient({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/clients/${id}`);
        if (response.success) setClient(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/clients">Client</Link>
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}
          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} Client
          </h1>

          <p className="lead">
            {scope === "edit" ? "Edit" : "Add new"} Client record
          </p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/clients" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Clients
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave}>
                    <div className="card-body">
                      <h5 className="card-title">Personal Data</h5>

                      <div className="row mb-3">
                        <label htmlFor="name" className="col-sm-3 form-label">
                          Name
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              ref={nameRef}
                              id="name"
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              onChange={(e) =>
                                setClient({ ...client, name: e.target.value })
                              }
                              value={client?.name || ""}
                            />
                            <label htmlFor="name">Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="phonenumber"
                          className="col-sm-3 form-label"
                        >
                          Phone Number
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              id="phonenumber"
                              type="text"
                              className="form-control"
                              placeholder="Phone number"
                              onChange={(e) =>
                                setClient({
                                  ...client,
                                  phonenumber: e.target.value,
                                })
                              }
                              value={client?.phonenumber || ""}
                            />
                            <label htmlFor="phonenumber">Phone number</label>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="email" className="col-sm-3 form-label">
                          Email
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <input
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              onChange={(e) =>
                                setClient({ ...client, email: e.target.value })
                              }
                              value={client?.email || ""}
                            />
                            <label htmlFor="email">Email</label>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="address"
                          className="col-sm-3 form-label"
                        >
                          Address
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <textarea
                              id="address"
                              className="form-control"
                              placeholder="Address"
                              rows="7"
                              onChange={(e) =>
                                setClient({
                                  ...client,
                                  address: e.target.value,
                                })
                              }
                              value={client?.address || ""}
                            ></textarea>
                            <label htmlFor="address">Address</label>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="remarks"
                          className="col-sm-3 form-label"
                        >
                          Remarks
                        </label>
                        <div className="col-sm-9">
                          <div className="form-floating mb-3">
                            <textarea
                              id="remarks"
                              className="form-control"
                              placeholder="Remarks"
                              rows="7"
                              onChange={(e) =>
                                setClient({
                                  ...client,
                                  remarks: e.target.value,
                                })
                              }
                              value={client?.remarks || ""}
                            ></textarea>
                            <label htmlFor="remarks">Remarks</label>
                          </div>
                        </div>
                      </div>

                      {/* <!-- File Input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="attachments"
                          className="col-sm-3 col-form-label"
                        >
                          Attachments
                        </label>
                        <div className="col-sm-9">
                          <input
                            id="attachments"
                            className="form-control"
                            type="file"
                            multiple
                            onChange={(e) =>
                              setClient({
                                ...client,
                                attachments: e.target.files,
                              })
                            }
                          />
                        </div>
                      </div>
                      {/* <!-- END : File Input --> */}
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default ClientForm;
