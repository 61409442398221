import React, { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import useApp from "../../hooks/useApp";

export const ClientPolicyRequirement = (props) => {
  const { policy } = props;
  const [requirements, setRequirements] = useState([]);
  const [productRequirements, setProductRequirements] = useState([]);
  const [policyTypeRequirements, setPolicyTypeRequirements] = useState([]);
  const [policyItemRequirement, setPolicyItemRequirement] = useState({});
  const { showAlert } = useApp();

  const { api, abortSignal, auth } = useApi();

  useEffect(() => {
    const getRequirements = async () => {
      const { data: ptr } = await api.list(
        `/policies/policy-types/${policy.PolicyTypeId}/requirements`
      );

      const { data: pr } = await api.list(
        `/products/${policy.ProductId}/requirements`
      );

      let removeDups = [...ptr, ...pr];
      removeDups = removeDups.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name
          )
      );

      setRequirements([...removeDups]);
    };

    getRequirements();
    if (policy.policyRequirement) {
      setPolicyItemRequirement(JSON.parse(policy.policyRequirement));
    }
  }, [policy, auth]);

  const handleSaveRequirement = async (e) => {
    e.preventDefault();

    try {
      const data = {
        policyRequirement: JSON.stringify(policyItemRequirement),
      };

      const response = await api.update(
        `/policies/policy-items/${policy.id}/requirement`,
        data
      );
      if (response.ok) {
        showAlert("Policy requirement updated", "success");
      }
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSaveRequirement}>
      {requirements &&
        requirements.map((element) => {
          return (
            <div className="row mb-3" key={element.id}>
              <label htmlFor={element.id} className="col-sm-3 col-form-label">
                {element.name}
              </label>
              <div className="col-sm-9">
                {element.type === "check" ? (
                  <div className="form-check form-switch">
                    <input
                      id={element.id}
                      className="form-check-input mt-2"
                      type="checkbox"
                      checked={
                        policyItemRequirement[element.id] == true ? true : false
                      }
                      onChange={(e) =>
                        setPolicyItemRequirement({
                          ...policyItemRequirement,
                          [element.id]: e.target.checked,
                        })
                      }
                    />
                  </div>
                ) : element.type === "date" ? (
                  <DatePicker
                    id={element.id}
                    className="form-control"
                    selected={
                      new Date(policyItemRequirement?.[element.id]) || ""
                    }
                    onChange={(date) =>
                      setPolicyItemRequirement({
                        ...policyItemRequirement,
                        [element.id]: date,
                      })
                    }
                    isClearable
                  />
                ) : (
                  <input
                    className="form-control"
                    id={element.id}
                    onChange={(e) =>
                      setPolicyItemRequirement({
                        ...policyItemRequirement,
                        [element.id]: e.target.value,
                      })
                    }
                    value={
                      policyItemRequirement[element.id]
                        ? policyItemRequirement[element.id]
                        : ""
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      <button className="btn btn-primary" type="submit">
        Save requirement
      </button>
    </form>
  );
};

export default ClientPolicyRequirement;
