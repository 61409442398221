import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refresh: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleRefresh } = appSlice.actions;

export default appSlice.reducer;
