import React, { useEffect, useState, useTransition } from "react";
import moment from "moment";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const ProductListHeader = (props) => {
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Products List</h1>

          <p className="lead">List of insurrance companies Product offering</p>

          <div className="d-md-flex align-items-baseline my-3">
            <Link to="/products/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">{props.children}</div>
      </div>
    </section>
  );
};

const ProductListMain = (props) => {
  const { api, abortSignal, auth } = useApi();
  const [products, setProducts] = useState([]);
  const { id = "" } = useParams();
  const { scope = "" } = props;
  const { Paginate, setPages, searchParamString, setOptions } = usePagination();

  useEffect(() => {
    const url = `/products${scope}${id}${searchParamString}`;
    const callApi = async () => {
      try {
        const { data } = await api.list(url);
        setProducts(data);
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [searchParamString, auth]);
  return (
    <>
      {!props?.headless && (
        <>
          <h2 className="mb-0 mt-2">Products</h2>
          <div className="d-md-flex align-items-baseline my-3">
            <Link to="/products/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add new
            </Link>

            <FilterBar />
          </div>
        </>
      )}
      <div className="table-responsive bg-white">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Info</th>
              <th>Company</th>
              <th>Policy Type</th>
              <th>Commission Rate</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {products &&
              products?.map((product) => (
                <tr key={product.id}>
                  <td>
                    <Link to={product.id} className="btn-link">
                      {product?.name || ""}
                    </Link>
                  </td>
                  <td className="">
                    <p className="text-break">{product?.info || ""}</p>
                  </td>
                  <td>
                    <span className="text-muted">
                      <span className="p-end-2">
                        {product?.Company?.name || ""}
                      </span>
                    </span>
                  </td>
                  <td className="text-center fs-5">
                    <div className="">{product?.PolicyType?.name || ""}</div>
                  </td>
                  <td className="text-end fs-5">
                    <div className="">{product?.commissionRate || 0}</div>
                  </td>
                  <td className="text-center">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="btn-group">
                        <Link
                          to={`${product.id}/edit`}
                          title="Edit Product"
                          type="button"
                          className="btn btn-icon btn-outline-primary"
                        >
                          <i className="demo-psi-pen-5 icon-lg fs-5"></i>
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

const ProductList = (props) => {
  return props?.scope ? (
    <ProductListMain scope={props.scope} />
  ) : (
    <ProductListHeader>
      <ProductListMain headless={true} />
    </ProductListHeader>
  );
};

export default ProductList;
