import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";

const PolicyTypeForm = (props) => {
  const [policyType, setPolicyType] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const from = location?.state?.from?.pathname || `/policy-types/${id}`;
  const { api, abortSignal, auth } = useApi();
  const nameRef = useRef();
  const { scope = "add" } = props;

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const response =
        scope === "edit"
          ? await api.edit(`/policies/policy-types/${id}`, policyType)
          : await api.add("/policies/policy-types", policyType);
      if (response.ok) navigate(from, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scope === "add" && setPolicyType({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/policies/policy-types/${id}`);
        if (response.success) setPolicyType(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/policy-types">Policy types</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} Policy Type
          </h1>

          <p className="lead">
            {scope === "edit" ? "Edit" : "Add new"} Policy Type
          </p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/policy-types" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Policy types
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave}>
                    <div className="card-body">
                      {/* <!-- Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="name"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={nameRef}
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            onChange={(e) =>
                              setPolicyType({
                                ...policyType,
                                name: e.target.value,
                              })
                            }
                            value={policyType?.name || ""}
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- END : Text input --> */}

                      {/* <!-- Text input with help --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="contact_person"
                          className="col-sm-3 form-label"
                        >
                          Info
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            id="contact_person"
                            type="text"
                            className="form-control"
                            placeholder="Info"
                            aria-describedby="contactHelp"
                            onChange={(e) =>
                              setPolicyType({
                                ...policyType,
                                info: e.target.value,
                              })
                            }
                            value={policyType?.info || ""}
                            rows="12"
                          ></textarea>
                          <div id="contactHelp" className="form-text">
                            Enter details of the policy type
                          </div>
                        </div>
                      </div>
                      {/* <!-- END : Text input with help --> */}
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PolicyTypeForm;
