import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const ClaimList = (props) => {
  return props?.mini ? (
    <ClaimListMini />
  ) : (
    <ClaimListHead>
      <ClaimListMini headless={true} />
    </ClaimListHead>
  );
};

const ClaimListHead = (props) => {
  return (
    <section id="content" className="content">
      <div className="content__header content__boxed overlapping">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/claims">Claims</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                List
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">Claims List</h1>

          <p className="lead">
            List of all claims made with insurrance companies
          </p>

          <div className="d-md-flex align-items-baseline my-3">
            <Link to="/claims/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add Claim
            </Link>
            <FilterBar />
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap bg-white">{props.children}</div>
      </div>
    </section>
  );
};

const ClaimListMini = (props) => {
  const [branches, setBranches] = useState([]);
  const { id } = useParams();

  const { Paginate, setPages } = usePagination();

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.read(`/companies/${id}/branches`);
        if (response.success) {
          setBranches(response.data.row);
          setPages(response.data.count);
        }
      } catch (error) {
        console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <>
      {!props?.headless && (
        <div className="d-md-flex align-items-baseline mt-3">
          <Link to="branches/add" className="btn btn-info hstack gap-2 mb-3">
            <i className="demo-psi-add fs-4"></i>
            <span className="vr"></span>
            Add Policy
          </Link>

          <FilterBar />
        </div>
      )}
      <div className="table-responsive bg-white">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact</th>
              <th>Location</th>
              <th className="text-center">Status</th>
              {/* <th className="text-center">action</th> */}
            </tr>
          </thead>
          <tbody>
            {branches &&
              branches.map((branch) => (
                <tr key={branch.id}>
                  <td>
                    <Link to={`/branches/${branch.id}`} className="btn-link">
                      {branch.name}
                    </Link>
                  </td>
                  <td>
                    <pre>{branch.contact_person}</pre>
                  </td>
                  <td>
                    <span className="text-muted">
                      <i className="demo-pli-pin"></i> {branch.location}
                    </span>
                  </td>
                  <td className="text-center fs-5">
                    <div className="d-block badge bg-success">Active</div>
                  </td>
                  {/* <td className="text-center">-</td> */}
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

export default ClaimList;
