import React, { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import profileImage from "../assets/img/profile-photos/1.png";

export default function Header() {
  const { auth } = useAuth();
  const [searchText, setSearchText] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const { logout } = useLogout();
  const serializeFormQuery = new URLSearchParams();

  const root = document.getElementById("root");
  const toggleNav = () => {
    if (
      window.innerWidth < 992 ||
      (!root.classList.contains("mn--min") &&
        !root.classList.contains("mn--max"))
    ) {
      root.classList.toggle("mn--show");
    } else {
      root.classList.toggle("mn--min");
      root.classList.toggle("mn--max");
    }
  };

  const beginSearch = (e) => {
    e.preventDefault();
    serializeFormQuery.append("search", searchText);

    setSearchParams(serializeFormQuery);
  };

  const toggleSidebar = () => root.classList.toggle("sb--show");

  return auth.user ? (
    <header className="header">
      <div className="header__inner">
        {/* <!-- Brand --> */}
        <div className="header__brand">
          <div className="d-none d-xs-block">SIB</div>
          <div className="brand-wrap1">
            {/* <!-- Brand logo --> */}

            {/* <!-- Brand title --> */}
            <div className="brand-title">SURGE</div>

            {/* <!-- You can also use IMG or SVG instead of a text element. --> */}
          </div>
        </div>
        {/* <!-- End - Brand --> */}

        <div className="header__content">
          {/* <!-- Content Header - Left Side: --> */}
          <div className="header__content-start">
            {/* <!-- Navigation Toggler --> */}
            <button
              type="button"
              className="nav-toggler header__btn btn btn-icon btn-sm"
              aria-label="Nav Toggler"
              onClick={toggleNav}
            >
              <i className="demo-psi-view-list"></i>
            </button>

            {/* <!-- Searchbox --> */}
            <div className="header-searchbox">
              {/* <!-- Searchbox toggler for small devices --> */}
              <label
                htmlFor="header-search-input"
                className="header__btn d-md-none btn btn-icon rounded-pill shadow-none border-0 btn-sm"
                type="button"
              >
                <i className="demo-psi-magnifi-glass"></i>
              </label>

              {/* <!-- Searchbox input --> */}
              <form
                className="searchbox searchbox--auto-expand searchbox--hide-btn input-group"
                onSubmit={beginSearch}
                autoComplete="off"
              >
                <div className="d-flex d-none d-md-flex text-center pt-2">
                  <label
                    className="form-check-label h5 mb-0 me-3"
                    htmlFor="globalSearch"
                  >
                    Global Search
                  </label>
                  <div className="form-check form-switch">
                    <input
                      id="globalSearch"
                      className="form-check-input"
                      type="checkbox"
                    />
                  </div>
                </div>
                <input
                  id="header-search-input"
                  className="searchbox__input form-control bg-transparent1 border-primary"
                  type="search"
                  name="search"
                  placeholder="Type for search . . ."
                  aria-label="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                <div className="searchbox__backdrop">
                  <button
                    className="searchbox__btn header__btn btn btn-icon rounded shadow-none border-0 btn-sm"
                    type="button"
                    id="button-addon2"
                  >
                    <i className="demo-pli-magnifi-glass"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End - Content Header - Left Side --> */}

          {/* <!-- Content Header - Right Side: --> */}
          <div className="header__content-end">
            {/* <!-- Notification Dropdown --> */}
            <div className="dropdown">
              {/* <!-- Toggler --> */}
              <button
                className="header__btn btn btn-icon btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-label="Notification dropdown"
                aria-expanded="false"
              >
                <span className="d-block position-relative">
                  <i className="demo-psi-bell"></i>
                  <span className="badge badge-super rounded bg-danger p-1">
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </span>
              </button>

              {/* <!-- Notification dropdown menu --> */}
              <div className="dropdown-menu dropdown-menu-end w-md-300px">
                <div className="border-bottom px-3 py-2 mb-3">
                  <h5>Notifications</h5>
                </div>

                <div className="list-group list-group-borderless">
                  {/* <!-- List item --> */}
                  {/* <div className="list-group-item list-group-item-action d-flex align-items-start mb-3">
                    <div className="flex-shrink-0 me-3">
                      <i className="demo-psi-data-settings text-muted fs-2"></i>
                    </div>
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="h6 d-block mb-0 stretched-link text-decoration-none"
                      >
                        Your storage is full
                      </a>
                      <small className="text-muted">
                        Local storage is nearly full.
                      </small>
                    </div>
                  </div> */}

                  {/* <!-- List item --> */}
                  {/* <div className="list-group-item list-group-item-action d-flex align-items-start mb-3">
                    <div className="flex-shrink-0 me-3">
                      <i className="demo-psi-file-edit text-blue-200 fs-2"></i>
                    </div>
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="h6 d-block mb-0 stretched-link text-decoration-none"
                      >
                        Writing a New Article
                      </a>
                      <small className="text-muted">
                        Wrote a news article for the John Mike
                      </small>
                    </div>
                  </div> */}

                  {/* <!-- List item --> */}
                  {/* <div className="list-group-item list-group-item-action d-flex align-items-start mb-3">
                    <div className="flex-shrink-0 me-3">
                      <i className="demo-psi-speech-bubble-7 text-green-300 fs-2"></i>
                    </div>
                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-start">
                        <a
                          href="#"
                          className="h6 mb-0 stretched-link text-decoration-none"
                        >
                          Comment sorting
                        </a>
                        <span className="badge bg-info rounded ms-auto">
                          NEW
                        </span>
                      </div>
                      <small className="text-muted">
                        You have 1,256 unsorted comments.
                      </small>
                    </div>
                  </div> */}

                  {/* <!-- List item --> */}
                  {/* <div className="list-group-item list-group-item-action d-flex align-items-start mb-3">
                    <div className="flex-shrink-0 me-3">
                      <img
                        className="img-xs rounded-circle"
                        src="./assets/img/profile-photos/7.png"
                        alt="Profile Picture"
                        loading="lazy"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="h6 d-block mb-0 stretched-link text-decoration-none"
                      >
                        Lucy Sent you a message
                      </a>
                      <small className="text-muted">30 minutes ago</small>
                    </div>
                  </div> */}

                  {/* <!-- List item --> */}
                  {/* <div className="list-group-item list-group-item-action d-flex align-items-start mb-3">
                    <div className="flex-shrink-0 me-3">
                      <img
                        className="img-xs rounded-circle"
                        src="./assets/img/profile-photos/3.png"
                        alt="Profile Picture"
                        loading="lazy"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <a
                        href="#"
                        className="h6 d-block mb-0 stretched-link text-decoration-none"
                      >
                        Jackson Sent you a message
                      </a>
                      <small className="text-muted">1 hours ago</small>
                    </div>
                  </div> */}

                  <div className="text-center mb-2">
                    <a href="#" className="btn-link">
                      Show all Notifications
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End - Notification dropdown --> */}

            {/* <!-- User dropdown --> */}
            <div className="dropdown">
              {/* <!-- Toggler --> */}
              <button
                className="header__btn btn btn-icon btn-sm"
                type="button"
                data-bs-toggle="dropdown"
                aria-label="User dropdown"
                aria-expanded="false"
              >
                <i className="demo-psi-male"></i>
              </button>

              {/* <!-- User dropdown menu --> */}
              <div className="dropdown-menu dropdown-menu-end w-md-450px">
                {/* <!-- User dropdown header --> */}
                <div className="d-flex align-items-center border-bottom px-3 py-2">
                  <div className="flex-shrink-0">
                    <img
                      className="img-sm rounded-circle"
                      src={profileImage}
                      alt="Profile Picture"
                      loading="lazy"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-0">{auth.user.name}</h5>
                    <span className="text-muted fst-italic">
                      {auth.user.email}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-7">
                    {/* <!-- Simple widget and reports --> */}
                    <div className="list-group list-group-borderless mb-3">
                      <div className="list-group-item text-center mb-3">
                        <p className="h1 display-1 text-green">0</p>
                        <p className="h6 mb-0">
                          <i className="demo-pli-basket-coins fs-3 me-2"></i>{" "}
                          New orders
                        </p>
                        <small className="text-muted">
                          You have new orders
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    {/* <!-- User menu link --> */}
                    <div className="list-group list-group-borderless h-100 py-3">
                      <a
                        href="#"
                        className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                      >
                        <span>
                          <i className="demo-pli-mail fs-5 me-2"></i>
                          Alerts
                        </span>
                        <span className="badge bg-danger rounded-pill">0</span>
                      </a>
                      <Link
                        to="/profile"
                        className="list-group-item list-group-item-action"
                      >
                        <i className="demo-pli-male fs-5 me-2"></i> Profile
                      </Link>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                      >
                        <i className="demo-pli-gear fs-5 me-2"></i> Settings
                      </a>
                      <button
                        onClick={logout}
                        className="list-group-item list-group-item-action"
                      >
                        <i className="demo-pli-unlock fs-5 me-2"></i> Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End - User dropdown --> */}

            {/* <!-- Sidebar Toggler --> */}
            <button
              className="sidebar-toggler header__btn btn btn-icon btn-sm"
              type="button"
              aria-label="Sidebar button"
              // onClick={toggleSidebar}
            >
              <i className="demo-psi-dot-vertical"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  ) : (
    <div></div>
  );
}
