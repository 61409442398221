import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import useApi from "../../hooks/useApi";
import useApp from "../../hooks/useApp";

const CompanyForm = (props) => {
  const { showAlert } = useApp();
  const [company, setCompany] = useState({});
  const [dropDowns, setDropDowns] = useState({ companyType: [] });
  const navigate = useNavigate();
  const { id } = useParams();
  const { api, abortSignal, auth } = useApi();
  const nameRef = useRef();
  const typeRef = useRef();
  const { scope = "add" } = props;

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...company,
        CompanyTypeId: company?.CompanyTypeId?.value || "",
      };

      if (!data.CompanyTypeId) {
        typeRef.current.focus();
        return showAlert("Insurrance company type is blank. Please choose one");
      }
      const response =
        scope === "edit"
          ? await api.edit(`/companies/${id}`, data)
          : await api.add("/companies", data);
      if (response.success) {
        showAlert("Company added", "success");
        navigate(`/companies/${id ? id : response.data.id}`, { replace: true });
      }
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  const getDropDowns = async () => {
    try {
      const models = ["companyType"];
      const { data } = await api.list(`/dropdowns`, models);
      setDropDowns(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scope === "add" && setCompany({});
  }, [scope]);

  useEffect(() => {
    nameRef.current.focus();
    const callApi = async () => {
      try {
        const response = await api.read(`/companies/${id}`);
        if (response.success) setCompany(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    scope === "edit" && callApi();

    getDropDowns();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <section id="content" className="content">
      <div className="content__header content__boxed">
        <div className="content__wrap">
          {/* <!-- Breadcrumb --> */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/desktop">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/companies">Insurrance Companies</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {scope === "edit" ? "Edit" : "Add"}
              </li>
            </ol>
          </nav>
          {/* <!-- END : Breadcrumb --> */}

          <h1 className="page-title mb-0 mt-2">
            {scope === "edit" ? "Edit" : "Add"} Company
          </h1>

          <p className="lead">
            {scope === "edit" ? "Edit" : "Add new"} Insurrance company
          </p>

          <div className="d-md-flex align-items-baseline mt-3">
            <Link to="/companies" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-file fs-4"></i>
              <span className="vr"></span>
              View Companies
            </Link>
          </div>
        </div>
      </div>

      <div className="content__boxed">
        <div className="content__wrap">
          <section>
            <div className="row">
              <div className="col-xxl-6 col-lg-12 col-md-12 mb-3">
                <div className="card h-100">
                  <form onSubmit={handleSave} autoComplete="off">
                    <div className="card-body">
                      {/* <!-- Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="name"
                          className="col-sm-3 col-form-label"
                        >
                          Company name
                        </label>
                        <div className="col-sm-9">
                          <input
                            ref={nameRef}
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Company name"
                            onChange={(e) =>
                              setCompany({ ...company, name: e.target.value })
                            }
                            value={company?.name || ""}
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- END : Text input --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="companyType"
                          className="col-sm-3 col-form-label"
                        >
                          Insurrance Type
                        </label>
                        <div className="col-sm-9">
                          <Select
                            ref={typeRef}
                            id="companyType"
                            options={
                              dropDowns && dropDowns["companyType"]
                                ? dropDowns["companyType"]
                                : []
                            }
                            onChange={(selectedOption) =>
                              setCompany({
                                ...company,
                                CompanyTypeId: selectedOption,
                              })
                            }
                            value={company?.CompanyTypeId || ""}
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- Text input with help --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="contact_person"
                          className="col-sm-3 form-label"
                        >
                          Contact Person
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            id="contact_person"
                            type="text"
                            className="form-control"
                            placeholder="Contact Person"
                            aria-describedby="contactHelp"
                            onChange={(e) =>
                              setCompany({
                                ...company,
                                contact_person: e.target.value,
                              })
                            }
                            value={company?.contact_person || ""}
                            rows="5"
                          ></textarea>
                          <div id="contactHelp" className="form-text">
                            Enter details of company contact person
                          </div>
                        </div>
                      </div>
                      {/* <!-- END : Text input with help --> */}

                      {/* <!-- Textarea --> */}
                      <div className="row mb-3">
                        <label
                          htmlFor="address"
                          className="col-sm-3 col-form-label"
                        >
                          Addrees
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            id="address"
                            className="form-control"
                            placeholder="Address"
                            rows="7"
                            onChange={(e) =>
                              setCompany({
                                ...company,
                                address: e.target.value,
                              })
                            }
                            value={company?.address || ""}
                          ></textarea>
                        </div>
                      </div>
                      {/* <!-- END : Textarea --> */}
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary d-flex" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default CompanyForm;
