import React, { useState, useEffect } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import useApi from "../../hooks/useApi";
import usePagination from "../../hooks/usePagination";
import { FilterBar } from "../../widgets";

const PolicyTypeProdcuctList = (props) => {
  return <PolicyTypeProdcuctListMini />;
};

const PolicyTypeProdcuctListMini = (props) => {
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  const { Paginate, setPages, searchParamString } = usePagination();

  const { api, abortSignal, auth } = useApi();
  useEffect(() => {
    const callApi = async () => {
      try {
        const { data } = await api.list(
          `/policies/policy-types/${id}/products${searchParamString}`
        );
        setProducts(data);
      } catch (error) {
        //console.error(error);
      }
    };
    callApi();

    const abortApiCall = () => abortSignal && abortSignal.abort;
    return abortApiCall;
  }, [auth]);

  return (
    <>
      {!props?.headless && (
        <>
          <h2 className="mb-0 mt-2">Products</h2>
          <div className="d-md-flex align-items-baseline mt-3">
            <Link
              to="/policy-types"
              className="btn btn-primary hstack gap-2 mb-3 me-2"
            >
              <i className="demo-psi-back fs-4"></i>
              <span className="vr"></span>
              Back
            </Link>
            <Link to="/products/add" className="btn btn-info hstack gap-2 mb-3">
              <i className="demo-psi-add fs-4"></i>
              <span className="vr"></span>
              Add Product
            </Link>

            <FilterBar />
          </div>
        </>
      )}
      <div className="table-responsive bg-white">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th className="text-center">Status</th>
              {/* <th className="text-center">action</th> */}
            </tr>
          </thead>
          <tbody>
            {products &&
              products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <Link to={`/products/${product.id}`} className="btn-link">
                      {product?.name}
                    </Link>
                  </td>
                  <td>
                    <span className="text-muted">
                      <i className="demo-pli-pin"></i> {product?.info}
                    </span>
                  </td>
                  <td className="text-center fs-5">
                    <div className="d-block badge bg-success">Active</div>
                  </td>
                  {/* <td className="text-center">-</td> */}
                </tr>
              ))}
          </tbody>
        </table>
        <Paginate />
      </div>
    </>
  );
};

export default PolicyTypeProdcuctList;
