import React from "react";
import NavigateBackButton from "./NavigateBackButton";

const FilterBar = (props) => {
  const { filter = false } = props;
  return (
    <div className="d-flex align-items-center gap-1 text-nowrap ms-auto mb-3">
      {filter && (
        <>
          <span className="d-none d-md-inline-block me-2">Sort by : </span>
          <select className="d-inline-block w-auto form-select">
            <option value="date-created">Date Created</option>
            <option value="date-modified">Date Modified</option>
            <option value="frequency-used">Frequency Used</option>
            <option value="alpabetically">Alpabetically</option>
            <option value="alpabetically-reversed">
              Alpabetically Reversed
            </option>
          </select>

          <button type="button" className="btn btn-light">
            Filter
          </button>
        </>
      )}
      <NavigateBackButton />
    </div>
  );
};

export default FilterBar;
