import React, { useContext } from "react";
import { AppContext } from "../context/AppProvider";
import Swal from "sweetalert2";

const useApp = () => {
  /**
   *
   * @param {string} msg
   * @param {string} icon posible values: "info, error, success, warning"
   * @returns displays a sweetalert message
   */
  const showAlert = async (msg = "Loading....", icon = "info") => {
    return Swal.fire({
      position: "top",
      icon: icon,
      text: msg,
      showConfirmButton: false,
      timer: 2500,
    });
  };

  return { ...useContext(AppContext).app, showAlert };
};

export default useApp;
