import React, { useEffect } from "react";
import useIsLoading from "../hooks/useIsLoading";

const Loading = () => {
  const { isLoading } = useIsLoading();

  return (
    isLoading && (
      <div
        id="loading-progress"
        className="bg-gray d-flex justify-content-center align-items-center1"
        style={{
          zIndex: 9000,
          right: 0,
          bottom: 0,
          top: 0,
          left: 0,
          position: "absolute",
          opacity: 0.4,
        }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <span className="text-black fs-2 ms-2">Loading...</span>
      </div>
    )
  );
};

export default Loading;
